import { vModelDynamic as _vModelDynamic, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0009be57"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled", "type"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createBlock(_component_el_tooltip, {
    effect: "dark",
    content: _ctx.$t('rename'),
    placement: "bottom",
    disabled: _ctx.disableTooltip || !_ctx.canWrite
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["none-outline-input", {
            [_ctx.customClass]:Boolean(_ctx.customClass),
            editing:_ctx.interEditMode
         }]),
        ref: "wrapperRef"
      }, [
        _withDirectives(_createElementVNode("input", {
          class: "edit-title-input",
          ref: "inputRef",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.titleCopy) = $event)),
          onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args))),
          onKeydown: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleKeydown && _ctx.handleKeydown(...args))),
          disabled: !_ctx.canWrite,
          type: _ctx.inputType,
          onFocus: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleInputFocus && _ctx.handleInputFocus(...args))),
          onBlur: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleInputBlur && _ctx.handleInputBlur(...args)))
        }, null, 40, _hoisted_1), [
          [_vModelDynamic, _ctx.titleCopy]
        ]),
        _createElementVNode("span", {
          class: "et-scale",
          ref: "scaleRef"
        }, _toDisplayString(_ctx.titleCopy), 513)
      ], 2)
    ]),
    _: 1
  }, 8, ["content", "disabled"]))
}