
import {Options, Vue} from 'vue-class-component';
import MetaButton from "@/components/MetaButton.vue";
import {ViewNames} from "@/router"
import UserAvatar from "@/components/UserAvatar.vue";
import {PropType} from "vue";
import {ShareUserData, UserInfoData} from "@/types/api/UserInfoData";
import BounceTransitionGroup from "@/components/transitions/BounceTransitionGroup.vue";
import MetaDialog from "@/components/MetaDialog.vue";
import {CanvasDTO} from "@/types/api/InfiniteData";
import {getCanvas, getCanvasShareLink, getCanvasShareUsers} from '@/api';
import MetaPopper from "@/components/MetaPopper.vue";
import SvgIcon from "@/components/SvgIcon.vue";
import {colorLog, copyToClipboard} from "@/utils";
import {ElMessage} from "element-plus";
import CanvasPrivilegeSetting from "@/components/fabric/CanvasPrivilegeSetting.vue";
import SlideI18nText from "@/components/SlideI18nText.vue";
import {CanvasCollaborationAccessType} from "@/types/api/Common";
import QuotaEditor from "@/components/fabric/QuotaEditor.vue";
import FadeTransition from "@/components/transitions/FadeTransition.vue";
import RotateRing from "@/components/RotateRing.vue";
import store from "@/store";
import {MetaEvents} from "@/types/Events";

@Options<FabricHeader>({
  components: {
    RotateRing,
    FadeTransition,
    QuotaEditor,
    SlideI18nText,
    CanvasPrivilegeSetting, SvgIcon, MetaPopper, MetaDialog, BounceTransitionGroup, UserAvatar, MetaButton },
  props: {
    userInfos:{
      required:true,
      type:[Object,null]  as PropType<UserInfoData[]>
    },
    imagesLayerClick: {
        required:true,
        type: Function
    }
  },
  watch:{

  }
})
export default class FabricHeader extends Vue {
  inviteCollaboratorDialogVisible = false;
  inviteCollaboratorDialogOpened = false;
  canvasDTO:CanvasDTO|null=null;
  shareLink = "";
  canvasId  = "";
  shareUser:Array<ShareUserData>=[];
  CanvasCollaborationAccessType = CanvasCollaborationAccessType;
  inviteLinkCanEdit = CanvasCollaborationAccessType.READ_ONLY;
  linkSettingPopperVisible = false;

  canvasQuotaDetailLoaded = false;

  $refs!:{
    linkSettingPopper:MetaPopper
  }

  handleLogClicked(){
    this.$router.push({
      name:ViewNames.Home
    });
    store.eventBus.emit(MetaEvents.REPEAT_TO_HOME);
  }

  mounted(){
    this.canvasId=this.$route.query.id as string;
    getCanvas(this.canvasId).then(res=>{
      if(res.data.code==200){
        this.canvasDTO=res.data.data;
      }
    })

  }

  async handleInviteCollaboratorBtnClicked(){
    this.canvasQuotaDetailLoaded = false;
    if(!this.shareLink){
      await this.getCanvasShareLink();
    }
    const res = await getCanvasShareUsers(this.canvasId);
    this.shareUser = res.data.data;

    this.canvasQuotaDetailLoaded = true;
    this.inviteCollaboratorDialogVisible = !this.inviteCollaboratorDialogVisible;
  }

  handleInviteCollaboratorDialogOpened(){
    this.inviteCollaboratorDialogOpened = true;
  }

  handleInviteCollaboratorDialogClosed(){
    this.inviteCollaboratorDialogOpened = false;
  }

  handleCanvasQuotaNumChanged(){
    colorLog.blue('CanvasQuotaNumChanged');
  }

  handleUserQuotaChanged(){
    colorLog.orange('UserQuotaChanged')
  }

  handleUserAccessTypeChanged(accessType:CanvasCollaborationAccessType){
    colorLog.orange('UserAccessTypeChanged',accessType)
  }

  async getCanvasShareLink(){
    if(this.canvasDTO!=null) {
      const res = await getCanvasShareLink(this.canvasDTO.id);
      this.shareLink=res.data.data;

    }
  }

  handleCopyBtnClicked(){
    copyToClipboard(this.shareLink);
    ElMessage.info(this.$t('copyTip'));
  }

  handleInviteDialogBeforeClose(){
    this.linkSettingPopperVisible = false;
  }
}
