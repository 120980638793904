import SockJS from "sockjs-client";

/**
 * 自动重连的SockJS客户端
 */
export default class ReconnectSockJsClient {
    /**
     *
     * @param url
     */
    constructor(url) {
        this.url = url;
        this.onopenfun = null;
        this.onmessagefun = null;
        this.onerrorfun = null;
        this.retryCounter = 0;
        this.retryThreshold = 3;
    }

    connect() {
        const sock = this.sock = new SockJS(this.url,undefined,{timeout:5000,transports:"websocket"});
        sock.onopen = this.onopen.bind(this);
        sock.onmessage = this.onmessage.bind(this);
        sock.onclose = this.onclose.bind(this);
        sock.onerror = this.onerror.bind(this);
        this.retryCounter++
    }

    close(){
        if(this.sock){
            this.sock.close();
        }
    }

    reconnect() {
        if (this.retryCounter > this.retryThreshold) {
            console.error("自动重试次数超过限制！！！");
            return;
        }

        //不需要重连
    }

    send(msg) {
        this.sock.send(msg);
    }

    onopen(e) {
        this.retryCounter = 0;
        if (this.onopenfun) {
            this.onopenfun(e);
        }
    }

    onmessage(e) {
        if (this.onmessagefun) {
            this.onmessagefun(e)
        }
    }

    onclose(e) {
        // console.error(e);
        // this.reconnect();
        if (this.onerrorfun) {
            this.onerrorfun(e)
        }
    }

    onerror(e) {
        // console.error(e);
        // this.reconnect();
        if (this.onerrorfun) {
            this.onerrorfun(e)
        }
    }
}