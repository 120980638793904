
import {Options, Vue} from 'vue-class-component';
import NoneOutlineInput from "@/components/NoneOutlineInput.vue";
import {colorLog} from "@/utils";

@Options<QuotaEditor>({
  components: {NoneOutlineInput},
  props: {
    preNum:{
      required:true,
      type:[Number , null],
    },
    postNum:{
      required:true,
      type:[Number , String , null],
    }
  },
  watch:{
    postNum:{
      handler(newVal:number | string | null){
        this.interPostNum = newVal;
      },
      immediate:true
    },
    interPostNum:{
      handler(newVal:number){
       this.$emit('update:postNum',newVal);
      }
    }
  },
  emits:['change']
})
export default class QuotaEditor extends Vue {
  preNum!:number | null;
  postNum!:number | string | null;

  interPostNum = -1;

  handleBlur(oldVal:number){
    if( isNaN(Number(this.interPostNum))){
      this.interPostNum = oldVal;
    }
    this.$emit('blur');
  }
}
