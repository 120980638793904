import { renderSlot as _renderSlot, TransitionGroup as _TransitionGroup, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_TransitionGroup, {
    appear: "",
    css: false,
    onBeforeEnter: _ctx.onBeforeEnter,
    onEnter: _ctx.onEnter,
    onLeave: _ctx.onLeave
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 3
  }, 8, ["onBeforeEnter", "onEnter", "onLeave"]))
}