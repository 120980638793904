
    import {Options, Vue} from 'vue-class-component';
    import {createPopper, Instance, Placement, VirtualElement} from "@popperjs/core";
    import {colorLog, debounce, DomEventListenerManager, getScrollParentElement} from "@/utils";
    import {fabric} from "@/assets/lib/fabric";
    import {PropType} from "vue";
    import {CanvasMode} from "@/views/test/FabricConstant";

    @Options<CanvasFooterTools>({
        components: {},
        props: {
            undoClick: {
                required: false,
                type: Function
            },
            redoClick: {
                required: false,
                type: Function
            },
            gridClick: {
                required: false,
                type: Function
            },
            pointerClick: {
                required: false,
                type: Function
            },
            eraserClick: {
                required: false,
                type: Function
            },
            aClick: {
                required: false,
                type: Function
            },
            zoomInClick: {
                required: false,
                type: Function
            },
            zoomOutClick: {
                required: false,
                type: Function
            },
            activeMode: {
                required: true,
                type: String,
                default: CanvasMode.INSERT
            },
        },
        emits: [],
        inject: []
    })
    export default class CanvasFooterTools extends Vue {
        referenceRect!: fabric.Rect;
        popperContainerId!: string;
        CANVAS_MODE=CanvasMode;
        domEventListenerManager = new DomEventListenerManager();
        virtualElement: VirtualElement = {
            contextElement: undefined,
            getBoundingClientRect: () => ({
                width: 0,
                height: 0,
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            })
        }
        $refs!: {
            selfRef: HTMLElement
        }
        api = {}

        created() {
        }

        mounted() {
        }

        beforeUnmount() {
            this.domEventListenerManager.destroy();
        }
        handleGridClick(){
          // @ts-ignore
          if(this.activeMode===CanvasMode.TRANSFER_DRAWING){
            return;
          }
          // @ts-ignore
          this.gridClick();
        }
    }
