
import {Options, Vue} from 'vue-class-component';
import {createPopper, Instance, OptionsGeneric, Placement, State, VirtualElement} from "@popperjs/core";
import {colorLog, debounce, DomEventListenerManager,getScrollParentElement} from "@/utils";
import {fabric} from "@/assets/lib/fabric";
import {Prop, PropType} from "vue";
import {OffsetsFunction} from "@popperjs/core/lib/modifiers/offset";
type BoundingRectFun = () => ClientRect

@Options<CanvasRectButtonToolbar>({
  components: { },
  props: {
    referenceRect:{
      required:true,
      type:[Object , null]
    },
    customWrapperClass:{
      required:false,
      type:String,
      default:''
    },
    customPopperClass:{
      required:false,
      type:String,
      default:''
    },
    placement:{
      required:false,
      type: String as PropType<Placement>,
      default:'right-end'
    },
    offset:{
      required:false,
      type: [Array , Function] as PropType<number[] | OffsetsFunction >,
      default:[0,10]
    },
    disablePreventOverflow:{
      required:false,
      type:Boolean,
      default: false,
    }
  },
  emits:['click'],
  inject:['canvasContainerClass','canvasScrollerClass','canvasToolbarPopperContainerClass']
})
export default class CanvasRectButtonToolbar extends Vue {
  visible = false;
  updating = false;
  customWrapperClass!:string;
  customPopperClass!:string;
  disablePreventOverflow!:boolean;
  referenceRect!: fabric.Rect;
  offset!:number[] | OffsetsFunction;
  canvasToolbarPopperContainerClass!:string;
  popperInstance:Instance | null = null;
  domEventListenerManager = new DomEventListenerManager();
  canvasContainerClass!:string;
  canvasScrollerClass!:string;
  placement!:Placement;
  virtualElement:VirtualElement = {
    contextElement:undefined,
    getBoundingClientRect:() => ({
      width: 0,
      height: 0,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    })
  }
  debounceShow = debounce(() => {
    this.showFn();
    this.updating = false;
  },200);
  $refs!:{
    selfRef:HTMLElement
  }
  api = {
    getVisible: () => this.visible,
    getUpdating: () => this.updating,
    show:( ) => {
      this.showFn();
    },
    update:( ) => {
      this.visible = false;
      this.updating = true;
      this.debounceShow();
    },
    hide:() => {
      this.visible = false;
    }
  }
  showFn = function(this:CanvasRectButtonToolbar){
    if(!this.popperInstance){
      this._createPopper();
    }
    this.visible = true;
    this.popperInstance?.update();
  }

  getReferenceRect(){
    const boundingRect = this.referenceRect.getBoundingRect();
    const {left:offsetX,top:offsetY} = document.getElementsByClassName(this.canvasContainerClass).item(0)!.getBoundingClientRect();
    boundingRect.width = this.referenceRect.getScaledWidth();
    boundingRect.height = this.referenceRect.getScaledHeight();
    boundingRect.left += offsetX;
    boundingRect.top += offsetY;
    const rect = {
      ...boundingRect,
      x:boundingRect.left,
      y:boundingRect.top,
      bottom:boundingRect.top + boundingRect.height,
      right:boundingRect.left + boundingRect.width
    }
    return rect
  }

  _createPopper(){
    this.virtualElement.getBoundingClientRect = this.getReferenceRect;
    const contextElement = document.querySelector('.' + this.canvasScrollerClass)!;
    this.virtualElement.contextElement = contextElement;

    this.popperInstance = createPopper(this.virtualElement,this.$refs.selfRef,{
      placement: this.placement,
      modifiers:[
        {
          name: 'offset',
          options: {
            offset: this.offset,
          },
        },
        {
          name: 'flip',
          enabled:this.disablePreventOverflow ? false : true,
          options: {
            boundary: contextElement,
          },
        },
        {
          name: 'preventOverflow',
          enabled:this.disablePreventOverflow ? false : true,
          options: {
            boundary: contextElement,
            rootBoundary: contextElement,
            altBoundary: true,
            tether: false
          },
        },
      ]
    })


    const scrollParent = getScrollParentElement(contextElement);
    this.domEventListenerManager.registerListener(scrollParent,'scroll',() => {
      if(!this.referenceRect)return;
      this.popperInstance!.update();
    });
  }

  created(){

  }

  mounted(){
    this.showFn = this.showFn.bind(this);
  }

  beforeUnmount(){
    this.popperInstance?.destroy();
    this.domEventListenerManager.destroy();
  }
}
