
import {colorLog, DomEventListenerManager} from "@/utils";
import {
  watch,
  ref,
  reactive,
  unref,
  nextTick,
  toRef,
  onMounted,
  onBeforeUnmount,
  computed,
  defineComponent,
  PropType
} from "vue";
import anime from "animejs";
import {ElMessage} from "element-plus";
import {useI18n} from "vue-i18n";


export default defineComponent(
    {
      name: "NoneOutlineInput",
      props:{
        editMode:{
          required:false,
          type:Boolean,
          default:false
        },
        uploadFn:{
          required:false,
          type:Function as PropType<(value:string) => void>
        },
        disableTooltip:{
          required:false,
          type:Boolean
        },
        title:{
          required:true,
          type:[String,Number]
        },
        maxLength:{
          required:false,
          type:Number
        },
        canWrite:{
          required:false,
          type:Boolean,
          default: true
        },
        inputType:{
          required:false,
          type:String as PropType<'text' | 'number'>,
          default:'text'
        },
        customClass:{
          required:false,
          type:String,
          default:''
        }
      },
      setup(props,ctx){
        const interEditMode = ref(false);
        const domEventListenerManager = new DomEventListenerManager();
        const inputRef = ref<HTMLElement>();
        const scaleRef = ref<HTMLElement>();
        const wrapperRef = ref<HTMLElement>();
        const storeOldValue = ref<number | string>();

        const titleBackup = ref('');
        watch(toRef(props,'editMode'),async (modeVal) => {
          interEditMode.value = modeVal;
          if(modeVal) {
            titleBackup.value = String(props.title);
            await nextTick();
            inputRef.value!.focus();
          }
        },{
          immediate:true,
        });
        watch(interEditMode,(modeVal) => {
          ctx.emit('update:editMode',modeVal);
        });

        const titleCopy = ref('');
        watch([toRef(props,'title'),toRef(props,'maxLength'),interEditMode],() => {
          let str = String(props.title);
          if(!interEditMode.value && props.maxLength && str.length >= props.maxLength){
            str = str.substring(0,props.maxLength) + '...';
          }
          titleCopy.value = str;
        },{
          immediate:true
        });

        watch(titleCopy,async (newTitle) => {
          await nextTick();
          setInputWidth();
        });

        const handleInput = function(event:InputEvent){
          titleCopy.value = (event.target as HTMLInputElement).value;
          ctx.emit('update:title',titleCopy.value);
        }
        const handleKeydown = function(event:KeyboardEvent){
          switch(event.key){
            case 'Enter':
              switchToDisplayMode(true);
              break;
            case 'Escape':
              switchToDisplayMode(false);
              break;
          }
        }

        //注册一个全局监听事件,来控制编辑和展示的模式
        domEventListenerManager.registerListener(window,'click',(event) => {
          const isClickedWithinWrapper = wrapperRef.value!.contains(event.target);
          if(isClickedWithinWrapper) {
            switchToEditMode();
          }else{
            switchToDisplayMode(true);
          }
        },{capture:true});

        const toggleMode = () => {
          if(interEditMode.value){
            switchToDisplayMode();
          }else{
            switchToEditMode();
          }
        }
        const switchToEditMode = () => {
          if(!props.canWrite) return;
          interEditMode.value = true;
        }
        const  switchToDisplayMode = (save = false) => {
          if(save && titleCopy.value !== titleBackup.value) {
            if(!/\S/g.test(titleCopy.value)){
              //都是空格
              const i18n = useI18n();
              ElMessage.error(i18n.t('canNotSetContentWithEmpty'));
              return;
            }
            interEditMode.value && props.uploadFn?.(titleCopy.value);
          }else{
            ctx.emit('update:title',titleBackup.value);
          }

          interEditMode.value = false;
          inputRef.value!.blur();
        }

        onBeforeUnmount(() => domEventListenerManager.removeListener());
        const getScaleWidth = () => {
          return anime.get(scaleRef.value!,'width');
        }
        const setInputWidth = (width = getScaleWidth()) => {
          inputRef.value!.style.setProperty('width',width + '');
        }
        onMounted(setInputWidth);

        const handleInputFocus = () => {
          storeOldValue.value = props.title;
        }
        const handleInputBlur = () => {
          ctx.emit('blue',storeOldValue.value);
        }

        return {
          inputRef,
          scaleRef,
          wrapperRef,
          interEditMode,
          titleCopy,
          handleInput,
          handleKeydown,
          handleInputFocus,
          handleInputBlur
        }
      },
    }
)
