import {fabric} from "@/assets/lib/fabric";
import { setObjectMid} from "@/views/test/FabricUtil";
import {
    AddOperation,
    Command,
    FabricHistoryManager,
    GroupAddOperation,
    GroupRemoveOperation, HISTORY_IGNORE_ADD_KEY, RemoveOperation
} from "@/views/test/FabricHistoryManager";
import {generateUUID} from "@/utils";
const TRANSFER_BOX_TYPE='transferBox';
export class TransferBoxManager {
    canvas:fabric.Canvas;
    historyManager:FabricHistoryManager;
    _mouseDownTransferBox:any = null;
    _listenerActive:boolean = false;
    constructor(canvas:fabric.Canvas,historyManager:FabricHistoryManager) {
        this.canvas = canvas;
        this.historyManager = historyManager;
        this._initListener();
    }

    activeListener():void{
        this._listenerActive = true;
    }
    disActiveListener():void{
        this._listenerActive = false;
    }

    isTransferBox(obj:any):boolean{
        if(!obj){
            return false;
        }
        if(obj.get(TRANSFER_BOX_TYPE)){
            return true;
        }
        return false;
    }
    isTransferring(obj:any):boolean{
        if(!obj){
            return false;
        }
        if(obj.get(this._getTransferringPropertyName())){
            return true;
        }else {
            return false;
        }
    }
    setTransferring(obj:any,transfferring:boolean){
        obj.set(this._getTransferringPropertyName(), transfferring);
    }
    _getTransferringPropertyName(){
        return this.getOldPropertyName("transferring");
    }

    _initListener():void{
        this.canvas!.on("mouse:down", (e) => {
            if (!this._listenerActive) {
                return;
            }
            let target = e.target;
            if(!this.isTransferBox(target)){
                this._mouseDownTransferBox = null;
            } else{
                this._mouseDownTransferBox = target;
            }
        });


        this.canvas!.on('object:added',(event) => {
            console.log('****added')
            if (!this._listenerActive) {
                return;
            }
            let path = event.target;
            if(!(path instanceof fabric.Path)){
                return;
            }
            //先移除之前添加画线的历史。
            // this.historyManager.popHistory();
            this.historyManager.markObjectAddIgnore(path,true);
            this.historyManager.doSomethingFreezeChangeListener(()=>{
                this.canvas?.remove(path!);
            });
            if(this._mouseDownTransferBox ==null||this.isTransferring(this._mouseDownTransferBox)){
                return;
            }
            const relativeBox = this._mouseDownTransferBox;
            if(this.isValidatePath(path,relativeBox)){
                console.log('group add**');
                (this._mouseDownTransferBox as fabric.Group).addWithUpdate(path)
                this.historyManager.addOperationAsCommand(new GroupAddOperation(path,this._mouseDownTransferBox,this.canvas),this._mouseDownTransferBox);
                path.bringForward();
            }
            let childRect = this._mouseDownTransferBox.getObjects('rect')[0];
            console.log('****childRect',childRect,this._mouseDownTransferBox)
            console.log(path);

            //因为group有可能扩大，所以将子rect也进行对应扩大，并对子rect的left、top进行对应修改。
            // childRect.width=this._mouseDownTransferBox.width;
            // childRect.height=this._mouseDownTransferBox.height;
            // childRect.left=-this._mouseDownTransferBox.width/2;
            // childRect.top=-this._mouseDownTransferBox.height/2;
            childRect.set('dirty',true)
            this.canvas.setActiveObject(this._mouseDownTransferBox)
            this.canvas.renderAll();
        })
    }
    isValidatePath(path: fabric.Path,relativeBox:any) {
        for(let command  of path.path!){
            // @ts-ignore
            for (let i = 1; i < command.length; ) {
                // @ts-ignore
                let x=command[i] as number,y=command[i+1] as number;
                if(x<relativeBox.left){
                    return false;
                }
                if(x>relativeBox.left+relativeBox.width){
                    return false;
                }
                if(y<relativeBox.top){
                    return false;
                }
                if(y>relativeBox.top+relativeBox.height){
                    return false;
                }
                i=i+2;
            }
        }
        return true;
    }
    convertRectToTransferBox(rect:fabric.Rect){
        // 先屏蔽掉historyManager的listener。
        this.historyManager.freezeListener();
        // console.log('convert')
        this.saveOldProperty(rect,'fill');
        // this.saveOldProperty(rect,'width');
        // this.saveOldProperty(rect,'height');
        rect.set("fill",'transparent');
        this.canvas.remove(rect);
        let box = new fabric.Group([rect]);
        // box.width=rect.width;
        // box.height=rect.height;
        box.stroke=rect.stroke;
        box.strokeWidth=rect.strokeWidth;
        let mid = generateUUID();
        setObjectMid(box,mid)
        // @ts-ignore
        box.set(TRANSFER_BOX_TYPE,true);
        this.canvas.add(box);
        this.canvas!.setActiveObject(box);
        this.canvas.renderAll();
        // 恢复historyManager的listener。
        this.historyManager.unFreezeListener();
        // 添加操作记录。
        this.historyManager.addCommand(new Command([
            new RemoveOperation(rect, this.canvas),
            new AddOperation(box, this.canvas)
        ],mid));
        return box;
    }
    convertTransferBoxToRect(transferredRect:fabric.Object){
        // 先屏蔽掉historyManager的listener。
        this.historyManager.freezeListener();
        // @ts-ignore
        let childRect = transferredRect.getObjects('rect')[0]
        // @ts-ignore
        const rect = new fabric.Rect({
            width:childRect.getScaledWidth(),
            height:childRect.getScaledHeight(),
            fill:this.getOldProperty(childRect,'fill'),
            stroke:transferredRect.stroke,
            strokeWidth:transferredRect.strokeWidth,
            left:transferredRect.left,
            top:transferredRect.top,
        });
        let mid = generateUUID();
        setObjectMid(rect,mid)
        this.canvas.remove(transferredRect);
        this.canvas.add(rect)
        this.canvas.setActiveObject(rect);
        // 恢复historyManager的listener。
        this.historyManager.unFreezeListener();
        // 添加操作记录。
        this.historyManager.addCommand(new Command([
            new RemoveOperation(transferredRect, this.canvas),
            new AddOperation(rect, this.canvas)
        ],mid));
        return rect;
    }
    getOldPropertyName(propertyName:string):string{
        return TRANSFER_BOX_TYPE + '_' + propertyName;
    }
    saveOldProperty(object:fabric.Object, oldPropertyName:string){
        // @ts-ignore
        object.set(this.getOldPropertyName(oldPropertyName), object.get(oldPropertyName));
    }
    getOldProperty(object:fabric.Object, oldPropertyName:string){
        // @ts-ignore
        return object.get(this.getOldPropertyName(oldPropertyName));
    }

}
