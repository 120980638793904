/**
 * request message type, when use websocket
 */
const RequestMessageType = {
    NONE: -1,
    /**
     *  获取文档内容
     */
    GET_CONTENT: 0,
    /**
     * 文档正文内容发生了变化
     */
    COMMAND: 1,
    /**
     * 同步版本消息
     */
    SYNC_VERSION: 2,
    /**
     * 批注
     */
    COMMENT: 3
}

/**
 * response message type, when use websocket
 */
const ResponseMessageType = {
    NONE: -1,
    /**
     *  获取文档内容
     */
    GET_CONTENT: 0,

    /**
     * 服务端推送给客户端的
     * 点对点消息
     */
    ACK: 1,

    /**
     * 服务端推送给客户端的广播
     * 除某个客户端之外的广播消息
     */
    BROADCAST: 2,

    /**
     * 所有连接相关通知
     */
    CONNECT :3,
    /**
     * 同步版本消息
     */
    SYNC_VERSION: 4,
    /**
     * 批注
     */
    COMMENT: 5,
    // 错误消息
    ERROR: 500
}
// Error类型消息的code
const ResponseErrorMsgCode = {
    NO_PERMISSION:402,
    NO_COMMENT_PERMISSION:2002,//沒有评论权限，该值只会在上传评论涉及的delta操作时发现无权限才会返回
}

const ConnectMessageType = {
    /**
     * 登陆成功
     */
    LOGIN_SUCCESS: 0,
    /**
     * 登陆失败
     */
    LOGIN_FAIL: 1,
    /**
     * 协作用户登录
     */
    COOPERATION_LOGIN: 2,
    /**
     * 协作用户登出
     */
    COOPERATION_LOGOUT: 3,

    /**
     * 其他用户与自己打招呼
     * 通常用于第一次登录上时服务端通知当前客户端有哪些正在编辑的用户
     */
    COOPERATION_HELLO: 4
}
const CommandType = {
    /**
     */
    NONE: 0,

    /**
     *
     */
    DELTA: 1,

    HEADER_DELTA:2,

    FOOTER_DELTA:3,

    NUMBERING:4
}

const CommentType = {
    CREATE: 0,
    DELETE: 1,
    SOLVE: 2,
    GET: 3
}
const COEditorEventType = {
    COMMON:{
        ACK_CHANGE:'ack'
    },
    CommentType:{
        CREATE: 'comments-create',
        DELETE: 'comments-delete',
        SOLVE: 'comments-solve',
        GET: 'comments-get'
    },
    ConnectStatus :{
        LOGIN_SUCCESS: "loginSuccess",
        /**
         * 登陆失败
         */
        LOGIN_FAIL: "loginFail",
        /**
         * 协作用户登录
         */
        COOPERATION_LOGIN: "cooperationLogin",
        /**
         * 协作用户登出
         */
        COOPERATION_LOGOUT: "cooperationLogout",

        /**
         * 其他用户与自己打招呼
         * 通常用于第一次登录上时服务端通知当前客户端有哪些正在编辑的用户
         */
        COOPERATION_HELLO: "cooperationHello",

        /**
         * 用户断开链接
         */
        COOPERATION_DISCONNECT: "cooperationDisconnect"
    },
    Content:{
        GET_CONTENT_SUCCESS:"getContentSuccess"
    },
    ERROR:{
        HANDLE_ERROR:"handleError"
    },
}

const EventsType = {
    TEXT_CHANGE :'text-change',
    OPEN_PAGE_HEADER: 'open-pageHeader',
    OPEN_PAGE_FOOTER: 'open-pageFooter',
    EDITOR_SELECTED: 'editor-selected',
    REQUEST_CREATE_COMMENTS: 'request-create-comments',
    EDITOR_ERROR:'editor-error',
    COMPOSITION_CHANGE:'composition-change',
    CHECK_ONLINE_CACHE: 'check-online-cache',
    START_CHECK:'start-check',
    HEADER_TAB_CHANGED:'header-tab-changed',
    LIST_ITEM_REFRESHED:'list-item-refreshed',//序号刷新完成
    TRACKING_CREATED: 'tracking-created',
}
const HeaderEventsType = {
    TAB_CHANGED:'tab-changed',
}

const Source = {
    /**
     * 调用API时默认模式 - 不记录到操作栈 && 操作转换
     */
    API: 'api',
    /**
     * 初始化时使用 - 不做任何处理
     */
    SILENT: 'silent',
    /**
     * 用户操作模式使用 - 记录操作栈 && 进行操作转换
     */
    USER: 'user',

}

const isUserSource = (source) => {
    return source === Source.USER;
}

export {
    RequestMessageType,
    ResponseMessageType,
    CommandType,
    ConnectMessageType,
    EventsType,
    Source,
    CommentType,
    COEditorEventType,
    ResponseErrorMsgCode,
    HeaderEventsType,
    isUserSource
}
