import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c78aa5d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ic-uo-ia-text" }
const _hoisted_2 = { class: "lsp-selected-icon-area" }
const _hoisted_3 = { class: "lsp-text" }
const _hoisted_4 = { class: "lsp-selected-icon-area" }
const _hoisted_5 = { class: "lsp-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")!
  const _component_MetaPopper = _resolveComponent("MetaPopper")!

  return (_openBlock(), _createBlock(_component_MetaPopper, {
    "custom-popper-class": "setting-popper",
    ref: "settingPopper",
    "popper-options": {
                          placement:'left',
                          modifiers:[
                           {
                              name: 'offset',
                              options: {
                                offset: [0, -58],
                              },
                            },
                          ]
                      },
    "popper-container": "body",
    "z-index": "4000",
    "create-after-ref-clicked": true,
    visible: _ctx.settingPopperVisible,
    "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.settingPopperVisible) = $event))
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["link-setting-privilege link-setting-privilege--view", {active:_ctx.accessType == _ctx.CanvasCollaborationAccessType.READ_ONLY}]),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleSettingOptionClicked(_ctx.CanvasCollaborationAccessType.READ_ONLY)))
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_SvgIcon, {
            class: "lsp-selected-icon",
            name: "ok"
          })
        ]),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('canView')), 1)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["link-setting-privilege link-setting-privilege--edit", {active:_ctx.accessType == _ctx.CanvasCollaborationAccessType.CAN_EDIT}]),
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleSettingOptionClicked(_ctx.CanvasCollaborationAccessType.CAN_EDIT)))
      }, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_SvgIcon, {
            class: "lsp-selected-icon",
            name: "ok"
          })
        ]),
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('canEdit')), 1)
      ], 2)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "ic-uo-input-append",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleMainBtnClicked && _ctx.handleMainBtnClicked(...args)))
      }, [
        _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t(_ctx.mainBtnTextPath)), 1),
        _createVNode(_component_SvgIcon, {
          class: _normalizeClass(["ic-uo-ia-arrow", {active:_ctx.settingPopperVisible}]),
          name: "arrow"
        }, null, 8, ["class"])
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}