
import {Options, Vue} from 'vue-class-component';
import {fabric} from '@/assets/lib/fabric';
import {colorLog, generateUUID, getCookie} from "@/utils";
import {Transform} from "@/assets/lib/fabric/fabric-impl";
import {getCanvasGeneratedImages, getInpaintingImages, getTransferImages} from "@/api";
import {RequestGenerateTaskData} from "@/types/api/GenerateTaskData";
import {ElMessage} from "element-plus";
import {GenerateInpaintData} from "@/types/api/GenerateInpaintData";
import {FabricHistoryManager} from './FabricHistoryManager';
import {TransferBoxManager} from "@/views/test/TransferBoxManager";
import {CanvasMode, DRAWING_BRUSH_MODE, NEED_RECORD_MODIFY_CANVAS_MODE} from "@/views/test/FabricConstant";
import FabricHeader from "@/components/fabric/FabricHeader.vue";
import {UserInfoData} from "@/types/api/UserInfoData";
import ReconnectSockJsClient from "@/assets/js/websocket/sock-js-client"
import {configData, Envs} from "@/config";
import {ConnectMessageType, ResponseMessageType} from "@/assets/js/websocket/websocket-message-type"
import NoneOutlineInput from "@/components/NoneOutlineInput.vue";
import {RectOperations} from "@/types/CanvasData";
import CanvasRectButtonToolbar from "@/components/fabric/CanvasRectButtonToolbar.vue";
import CreationData from "@/types/api/CreationData";
import CreatedImageData from "@/types/api/CreatedImageData";
import MetaDialog from "@/components/MetaDialog.vue";
import {ComponentPublicInstance} from "vue";
import {GenerateTransferImgData} from "@/types/api/GenerateTransferImgData";
import CanvasFooterTools from "@/components/fabric/CanvasFooterTools.vue";

@Options<Fabric>({
        components: {
          MetaDialog,
          CanvasRectButtonToolbar,
          NoneOutlineInput,
          FabricHeader,
          CanvasFooterTools
        },
        props: {},
        provide(){
          return{
            canvasContainerClass: this.canvasContainerClass,
            canvasScrollerClass: this.canvasScrollerClass,
            canvasToolbarPopperContainerClass: this.canvasToolbarPopperContainerClass
          }
        },
        watch: {
            isDrawingMode(newVal: boolean) {
                if (this.canvas) {
                    this.canvas.isDrawingMode = newVal;
                }
            },
          brushConfig:{
              handler:function (config){
                if(this.canvas.freeDrawingBrush instanceof fabric.EraserBrush){
                  this.canvas.freeDrawingBrush.width = config.eraserWidth;
                }else{
                  this.canvas.freeDrawingBrush.width = config.width;
                }
                this.canvas.freeDrawingBrush.color = config.color;
              },
              deep:true
          }
        },
        computed:{
          lastBrushConfigOptionWidth(){
            return this.brushConfigOption.width[this.brushConfigOption.width.length-1];
          }
        }
    })
    export default class Fabric extends Vue {
        RectButtons = RectOperations;

        canvasContainerClass = 'canvas-container';
        canvasToolbarPopperContainerClass = 'canvas-toolbar-popper-container';
        canvasWhiteBtnClass = 'canvas-white-btn'

        cutImgVisible = false;
        canvas?: fabric.Canvas;
        isDrawingMode = false;
        drawingBrushMode: DRAWING_BRUSH_MODE = DRAWING_BRUSH_MODE.NONE;
        canvasShapes: (any | fabric.Rect)[] = [];
        promptDialogVisible = false;
        promptMode:RectOperations = RectOperations.CREATE;
        promptText = '';
        promptingRectData?: Transform;
        canvasMode = CanvasMode.DRAWING;
        preModifyCanvasMode :CanvasMode|null = null;
        currentPointX = 0;
        currentPointY = 0;
        documentWidth = 0;
        documentHeight = 0;
        historyManager?: FabricHistoryManager;
        transferBoxManager: TransferBoxManager | null = null;
        startCut = false;
        startCutX = 0;
        startCutY = 0;
        clipArea = {
            x: 0,
            y: 0,
            w: 0,
            h: 0
        };
        cutImgSrc = '';
        mouseDown = false;
        cutUUID = '';
        insertUUID = '';
        DRAWING_BRUSH_MODE=DRAWING_BRUSH_MODE;
        collaboratingUserInfos:UserInfoData[] =[];
        currentSelectedRect: fabric.Object|null = null;
        debug = false;
        mouseEnterImageSelection = false;
        imageSelectionDatas: CreationData[] = [];
        imageSelectionBackup: Record<string, CreationData[]> = {};
        currentImageMid: string | null = null;
        currentImageSelectable: boolean = true;
        brushConfigOption={
          width:[5,8,10],
          color:['rgb(0,0,0)','rgb(255,0,0)','rgb(239,131,54)','rgb(255,239,2)','rgb(172,205,3)','rgb(2,152,68)','rgb(0,161,232)','rgb(29,32,136)','rgb(132,114,86)']
        }
        brushConfig={
          color:this.brushConfigOption.color[0],
          width: this.brushConfigOption.width[0],
          eraserWidth:10
        }
        canvasId  = "";
        socketClient:ReconnectSockJsClient | null = null;
        canvasTitle = 'untitled';
        currentSelectedImage:fabric.Image | null = null;
        currentSelectedDeletableObject: null | fabric.Object = null;
        $refs!:{
          rectButtonsRef: CanvasRectButtonToolbar,
          imageMoreBtnRef: CanvasRectButtonToolbar,
          generatedImagesRef: CanvasRectButtonToolbar,
          rectDeleteBtnRef: CanvasRectButtonToolbar,
          promptInput: ComponentPublicInstance & { focus():void },
          imagesSelectBtn:HTMLElement,
        }
        canvasRelativePan = {
            x: 0,
            y: 0
        };
        canvasScrollerClass = 'canvas-scroller';
        drawingRectMidMap: Record<string, RectOperations> = {};
        createdImages: CreatedImageData[] = [];
        showImagesOnRight: boolean = false;
        imageLayerShowHide: Record<string, boolean> = {};
        canvasMoveSpeed = 10;
        objectMaxWidth = 512;
        objectMinWidth = 128;


        async created() {
          // this.collaboratingUserInfos = await getFabricMembers();
        }


        imagesLayerClick() {
            this.showImagesOnRight = !this.showImagesOnRight;
        }

        undo() {
            if(this.historyManager?.undo()){
              this.autoSwitchCanvasMode();
            }
        }

        redo() {
            if(this.historyManager?.redo()){
              this.autoSwitchCanvasMode();
            }
        }
        autoSwitchCanvasMode(){
          console.log('autoSwitchCanvasMode:')
          if(this.existTransferBox()){
            // 如果有transferBox
            this.enterTransferStatus();
          }else{
            this.exitTransferStatus();
          }
        }

        focusPromptInput(){
          this.$refs.promptInput.focus();
        }

        handlePromptDialogClosed() {
            this.promptText = '';
        }

        handleDeleteObjectBtnClicked(){
          if(!this.currentSelectedDeletableObject) {
            colorLog.orange('currentSelectedDeletableObject not exist');
            return;
          }
          if(!this.notTransferMode()){
            this.exitTransferStatus();
          }
          const mid = this.currentSelectedDeletableObject['mid'];
          const target = this.getObjectByMId(this.canvas!, mid)
          this.canvas!.discardActiveObject();
          this.canvas!.remove(target!)
          delete this.drawingRectMidMap[mid];

          // 删掉图层
          this.createdImages = this.createdImages.filter((item: CreatedImageData) => item.mid != mid);
          this.currentSelectedDeletableObject = null;
          this.hideAllTools();
        }

        /**
         * 切换画笔模式（普通画笔和transfer画笔）
         * @param mode
         */
        switchDrawingBrushMode(mode: DRAWING_BRUSH_MODE,force:boolean=false) {
            this.drawingMode();
            this.drawingBrushMode = mode;
            if (mode === DRAWING_BRUSH_MODE.TRANSFER_BRUSH) {
                this.switchCanvasMode(CanvasMode.TRANSFER_DRAWING,force)
                this.transferBoxManager?.activeListener();
            } else {
                this.transferBoxManager?.disActiveListener();
            }

        }
        notTransferMode(){
          return this.canvasMode !== CanvasMode.TRANSFER_DRAWING;
        }

        drawingMode(force:boolean=false) {
            this.switchCanvasMode(CanvasMode.DRAWING,force)
            this.isDrawingMode = true;
            this.canvas!.selection = false;
            this.canvas!.hoverCursor = "default";
            this.canvas!.moveCursor = "default";
            this.canvas!.defaultCursor = "default";
            this.canvas!.freeDrawingBrush = new fabric.PencilBrush(this.canvas!);
            // this.canvas!.freeDrawingBrush.color = 'black';
            this.canvas!.freeDrawingBrush.color = this.brushConfig.color;
            this.canvas!.freeDrawingBrush.width = this.brushConfig.width;
            this.startCut = false;
            this.canvas!.getObjects().forEach((object) => {
                object.selectable = false;
                object.hoverCursor = "default";
                object.moveCursor = "default";
            });
            this.canvas!.renderAll();
        }

        insertMode(force:boolean=false) {
            this.switchCanvasMode(CanvasMode.INSERT,force)
            this.isDrawingMode = false;
            this.canvas!.selection = false;
            this.canvas!.hoverCursor = "default";
            this.canvas!.moveCursor = "default";
            this.canvas!.defaultCursor = "default";
            this.startCut = false;
            this.canvas!.selection = false;
            this.canvas!.getObjects().forEach((object) => {
                object.selectable = false;
                object.hoverCursor = "default";
                object.moveCursor = "default";
            });
            this.canvas!.renderAll();
        }

        dragCanvas(force:boolean=false) {
            this.switchCanvasMode(CanvasMode.DRAGGING,force)
            this.isDrawingMode = false;
            this.canvas!.selection = true;
            this.canvas!.setCursor("pointer");
            this.canvas!.hoverCursor = "pointer";
            this.canvas!.moveCursor = "pointer";
            this.canvas!.defaultCursor = "pointer";
            // const upperCanvas = document.querySelector(`.upper-canvas`) as HTMLElement;
            // upperCanvas.style.cursor = "pointer";
            this.canvas!.getObjects().forEach((object) => {
                object.hoverCursor = "pointer";
                object.moveCursor = "pointer";
            });
            this.canvas!.renderAll();
        }

        eraserMode(force:boolean=false) {
            this.switchCanvasMode(CanvasMode.ERASER_BRUSH,force)
            // this.canvas!.discardActiveObject();
            this.isDrawingMode = true;
            this.canvas!.freeDrawingBrush = new fabric.EraserBrush(this.canvas!);
            this.canvas!.freeDrawingBrush.width = this.brushConfig.eraserWidth;
            this.startCut = false;
            this.currentImageMid = null;
            this.currentImageSelectable = true;
            console.log('this.canvasMode === ', this.canvasMode);
            this.activeIfExistTransferBox();
        }
        switchCanvasMode(newMode:CanvasMode,force:boolean=false){
          if (this.canvasMode === newMode) {
            return;
          }
          if(!force){
            // 如果不是强制切换状态，则对当前状态、之前状态、以及需要切换状态进行检查，是否符合需求。
            if(this.preModifyCanvasMode===CanvasMode.TRANSFER_DRAWING){
              if(newMode===CanvasMode.INSERT){
                //不允许从transfer切换到insert。
                ElMessage.error('您需要先完成transfer绘画');
                throw new Error("无法切换")
              }
            }
          }
          this.canvasMode = newMode;
          if(NEED_RECORD_MODIFY_CANVAS_MODE.has(newMode)){
            this.preModifyCanvasMode = newMode;
          }
        }

        getObjects() {
            console.log('getObjects:', this.canvas!.getObjects())
            console.log('getActiveObjects:', this.canvas!.getActiveObjects())
        }

        async handlePromptConfirmed() {
            if(this.promptText.trim()===''){
              ElMessage.error('请输入文本')
              return;
            }
            this.promptDialogVisible = false;
            this.$refs.rectButtonsRef.api.hide();
            if (this.promptMode === RectOperations.CREATE) {
                // @ts-ignore
                this.drawingRectMidMap[this.currentImageMid!] = RectOperations.CREATE;
                this.currentSelectedRect!.setOptions({prompt: this.promptText});
                await this.generateImage(
                    {
                        text: this.promptText,
                        cnt: 6
                    },
                    this.currentSelectedRect!
                );
            } else if (this.promptMode === RectOperations.INPAINT) {
                // @ts-ignore
                this.drawingRectMidMap[this.currentImageMid!] = RectOperations.INPAINT;
                const imageBase64 = this.cutImgSrc.substring("data:image/png;base64,".length);
                const inpaintData = {
                    text: this.promptText,
                    b64_image: imageBase64,
                    b64_mask: "#fffffe"
                } as GenerateInpaintData;
                this.currentSelectedRect!.setOptions({prompt: this.promptText});
                await this.inpaintImage(
                    inpaintData,
                    this.currentSelectedRect!
                );
            }else if (this.promptMode === RectOperations.TRANSFER){
              console.log('transfer confirmed***')
              // @ts-ignore
              this.drawingRectMidMap[this.currentImageMid!] = RectOperations.TRANSFER;
              const imageBase64 = this.cutImgSrc.substring("data:image/png;base64,".length);
              const transferData = {
                text: this.promptText,
                b64_image: imageBase64,
              }
              try{
                this.exitTransferStatus();
                //  让transfer框可以移动。
                this.makeSelectTransferBoxCanMove()
                this.transferBoxManager?.setTransferring(this.currentSelectedRect!,true)
                await this.transferImage(transferData,this.currentSelectedRect!)
              }catch (e){
                console.error(e)
              }
            }

            this.promptText = '';
        }

        createImageClick() {
            this.insertMode();
            this.promptMode = RectOperations.CREATE;
            this.promptDialogVisible = true;
        }

        inpaintImageClick() {
            this.insertMode();
            this.generateImageDataByCutRect(this.insertUUID);
            if (this.debug) {
                this.downloadCutImage();
            }
            this.promptMode = RectOperations.INPAINT;
            this.promptDialogVisible = true;
        }
        showTransferPrompt(){
          // console.log(this, this.currentSelectedRect);
          let base64 = this.makeRectScreenShot(this.currentSelectedRect!);
          // console.log(base64)
          this.cutImgSrc = base64;
          this.promptMode = RectOperations.TRANSFER;
          this.promptDialogVisible = true;
        }
        makeSelectTransferBoxCanMove(){
          this.currentImageMid = this.currentSelectedRect!.get('mid');
          this.isDrawingMode = false;
          this.currentSelectedRect!.selectable = true;
          // this.switchCanvasMode(CanvasMode.SELECT);
        }

        cancelTransfer(){
          console.log('cancelTransfer',this.currentImageMid)
          let rect = this.transferBoxManager?.convertTransferBoxToRect(this.currentSelectedRect!)
          this.currentImageMid = rect!.mid;
          this.insertUUID = rect!.mid;
          // @ts-ignore
          this.currentSelectedRect = rect;
          this.exitTransferStatus();
        }
        exitTransferStatus(){
          this.promptMode = RectOperations.CREATE;
          this.insertMode(true);
        }
        enterTransferStatus(){
          this.promptMode = RectOperations.TRANSFER;
          this.promptDialogVisible = false;
          this.switchDrawingBrushMode(DRAWING_BRUSH_MODE.TRANSFER_BRUSH)
        }

        convertToTransferBox(){
            this.insertMode();
            let box = this.transferBoxManager?.convertRectToTransferBox(this.currentSelectedRect!);
            this.currentImageMid = box!.mid;
              this.insertUUID = box!.mid;
            // @ts-ignore
            this.currentSelectedRect = box;
            this.enterTransferStatus();
        }

        async generateImage(data: RequestGenerateTaskData, rect: fabric.Rect) {
            const {creationGroup: {creations}} = await getCanvasGeneratedImages(this.canvasId,data, (p) => {
                ElMessage.info('进度： ' + p.ratio)
            });

            this.afterGenerateImage(creations, rect);
        }

        async inpaintImage(data: GenerateInpaintData, rect: fabric.Rect) {
            const {creationGroup:{ creations }} = await getInpaintingImages(this.canvasId,data, (p) => {
                ElMessage.info('进度： ' + p.ratio)
            });
            this.afterGenerateImage(creations, rect);
        }

        async transferImage(data: GenerateTransferImgData, rect: fabric.Rect){
          const {creationGroup:{ creations }} = await getTransferImages(this.canvasId,data, (p) => {
            ElMessage.info('进度： ' + p.ratio)
          });
          this.transferBoxManager?.setTransferring(rect,false)
          this.afterGenerateImage(creations, rect);
        }

        afterGenerateImage(creations: CreationData[], rect: fabric.Rect) {
            // const {left, top, width, height} = rect.getBoundingRect();
            let {left, top, width, height} = rect;
            width = rect.getScaledWidth();
            height = rect.getScaledHeight();
            const rectMid = rect['mid'];
            for (const item of creations) {
              item.type = this.drawingRectMidMap[rectMid];
            }
            this.imageSelectionDatas = creations;
            const imageData = creations[0];
            fabric.Image.fromURL(imageData.urlSmall, (oImg: fabric.Image) => {
                oImg.applyFilters();
                const mid = generateUUID();
                console.log('afterGenerateImage  mid:', mid);
                oImg.setOptions({mid: mid});
                this.canvas!.add(oImg.set({
                    left,
                    top,
                }));

                const ratioX = width! / oImg.getScaledWidth();
                const ratioY = height! / oImg.getScaledHeight();
                oImg.set({
                    scaleX: ratioX,
                    scaleY: ratioY,
                });

                const rectObject = this.getObjectByMId(this.canvas!, rectMid);
                this.canvas!.discardActiveObject();
                this.canvas!.remove(rectObject!);
                this.canvasShapes.push(oImg);
                this.currentSelectedRect = null;
                this.currentSelectedDeletableObject = oImg;
                this.imageSelectionBackup[mid] = creations;
                // console.log('afterGenerateImage  rectMid:' + rectMid + ' this.insertUUID:' + this.insertUUID);
                if (rectMid === this.insertUUID) {
                    console.log('this.drawingRectMidMap[rectMid] === ', this.drawingRectMidMap[rectMid])
                    if (this.drawingRectMidMap[rectMid] === RectOperations.CREATE
                        || this.drawingRectMidMap[rectMid] === RectOperations.TRANSFER) {
                        this.currentImageMid = mid;
                        this.currentImageSelectable = true;
                        oImg.selectable = true;
                        oImg.hoverCursor = 'move';
                        oImg.moveCursor = 'move';
                        this.canvas!.setActiveObject(oImg);
                        this.showImageSelectionBtn(oImg);
                    } else if (this.drawingRectMidMap[rectMid] === RectOperations.INPAINT) {
                        this.currentImageSelectable = false;
                        oImg.selectable = false;
                        this.currentImageMid = mid;
                        this.showImageSelectionBtn(oImg);
                        oImg.sendToBack();
                    } else {
                        oImg.selectable = false;
                    }
                } else {
                    oImg.selectable = false;
                }

                const prompt = rect['prompt']!;
                const createdImage = {
                    mid: mid,
                    type: this.drawingRectMidMap[rectMid],
                    urlSmall: oImg.getSrc(),
                    createTime: new Date().getTime(),
                    prompt: prompt
                } as CreatedImageData;
                this.createdImages.unshift(createdImage);

                delete this.drawingRectMidMap[rectMid];
            });
        }

        addRectControls() {
            let _this = this;
            const createControls = (controls: ({
                name: string,
                type: string,
                handler: (eventData: MouseEvent, transformData: Transform, x: number, y: number) => boolean,
                x: number,
                y: number,
                offsetX: number,
                offsetY: number,
                sizeX: number | undefined,
                sizeY: number | undefined,
            })[]) => {
                const margin = 20;
                const fullString = controls.map(c => c.name).join('');
                for (let i = 0; i < controls.length; i++) {
                    const {name, handler, x, y, offsetX, offsetY, sizeX, sizeY} = controls[i];
                    fabric.Object.prototype.controls[name] = new fabric.Control({
                        x: x,
                        y: y,
                        offsetX: offsetX,
                        offsetY: offsetY,
                        sizeX: sizeX,
                        sizeY: sizeY,
                        cursorStyleHandler: (eventData, control, fabricObject) => {
                          if (this.canvasMode === CanvasMode.DRAGGING) return 'pointer';
                          if (this.startCut) return 'default';
                          if (fabricObject.type !== 'rect') return 'default';
                          return 'pointer';
                        },
                        mouseDownHandler: handler,
                        render: function (ctx, left, top, styleOverride, fabricObject) {
                            if (!(fabricObject instanceof fabric.Rect||_this.transferBoxManager?.isTransferBox(fabricObject))) {
                                colorLog.orange('!fabricObject instanceof fabric.Rect')
                                return;
                            }

                            if (_this.canvasMode === CanvasMode.INSERT && name !== "分辨率") {
                                return;
                            }

                            console.log('render ----------- fabricObject:', fabricObject);

                            ctx.save();
                            let drawName = name;
                            let textX = 10;
                            let textY = 20;
                            // @ts-ignore
                            let rectWidth = parseInt(fabricObject.width! * fabricObject.scaleX!);
                            // @ts-ignore
                            let rectHeight = parseInt(fabricObject.height! * fabricObject.scaleY!);
                            ctx.translate(left, top);
                            if (name === '分辨率') {
                                drawName = `${rectWidth} x ${rectHeight}`
                                ctx.font = "16px Palatino";
                            } else {
                                ctx.font = "24px Palatino";
                            }

                            const textWidth = ctx.measureText(drawName).width;
                            if (name === '分辨率') {
                                textX = (rectWidth - textWidth) / 2;
                            } else {
                                rectWidth = sizeX! - 20;
                                textX = (rectWidth - textWidth) / 2;
                            }
                            console.log('render rectWidth:', rectWidth);
                            ctx.fillStyle = '#40A9FF';
                            ctx.fillRect(0, 0, rectWidth, sizeY!);
                            ctx.fillStyle = 'rgb(255, 255, 255)';
                            // ctx.fillStyle = '#000';
                            if (name === '分辨率') {
                                ctx.font = "16px Palatino";
                            } else {
                                ctx.font = "24px Palatino";
                            }
                            ctx.fillText(drawName, textX, 20);
                            ctx.restore();
                        },
                    })
                }
            }

            createControls([
                {
                    name: '分辨率',
                    type: 'btn',
                    handler: (eventData, transformData) => {
                        // if (transformData.target.type !== 'rect') return false;
                        this.canvas!.setActiveObject(transformData.target);
                        return true;
                    },
                    x: -0.5,
                    y: -0.5,
                    sizeX: undefined,
                    sizeY: 30,
                    offsetY: -30,
                    offsetX: 0,
                },
                // {
                //     name: 'create',
                //     type: 'btn',
                //     handler: (eventData, transformData) => {
                //         const obj = transformData.target;
                //         console.log('create  obj === ', obj);
                //         this.canvas!.setActiveObject(obj);
                //         this.promptMode = "create";
                //         this.promptDialogVisible = true;
                //         this.promptingRectData = transformData;
                //         return true;
                //     },
                //     x: 0.5,
                //     y: -0.5,
                //     sizeX: 180,
                //     sizeY: 30,
                //     offsetY: 20,
                //     offsetX: 20,
                // },
                // {
                //     name: 'inpaint',
                //     type: 'btn',
                //     handler: (eventData, transformData, x, y) => {
                //         this.canvas!.setActiveObject(transformData.target);
                //         this.promptMode = "inpaint";
                //         this.generateImageDataByCutRect();
                //         this.promptDialogVisible = true;
                //         this.promptingRectData = transformData;
                //         return true;
                //     },
                //     x: 0.5,
                //     y: -0.5,
                //     sizeX: 180,
                //     sizeY: 30,
                //     offsetY: 70,
                //     offsetX: 20,
                // },
                // {
                //     name: 'Transfer',
                //     type: 'btn',
                //     handler: (eventData, transformData, x, y) => {
                //         this.canvas!.setActiveObject(transformData.target);
                //         alert("开发中")
                //         return true;
                //     },
                //     x: 0.5,
                //     y: -0.5,
                //     sizeX: 180,
                //     sizeY: 30,
                //     offsetY: 120,
                //     offsetX: 20,
                // },
                // {
                //     name: 'Upload',
                //     type: 'btn',
                //     handler: (eventData, transformData, x, y) => {
                //         this.canvas!.setActiveObject(transformData.target);
                //         alert("开发中")
                //         return true;
                //     },
                //     x: 0.5,
                //     y: -0.5,
                //     sizeX: 180,
                //     sizeY: 30,
                //     offsetY: 170,
                //     offsetX: 20,
                // }
            ])
        }

        mounted() {
            this.focusPromptInput = this.focusPromptInput.bind(this);
            //删掉调整大小的9个controls
            for(const key of Object.getOwnPropertyNames(fabric.Object.prototype.controls)){
              console.log('key === ', key);
              if (key != "br" && key != "bl" && key != "tr" && key != "tl") {
                Reflect.deleteProperty(fabric.Object.prototype.controls,key);
              }
            };

            const scale = window.devicePixelRatio;
            this.debug = this.$route.query.debug === 'true';
            this.cutUUID = generateUUID();
            const dom = document.getElementById('canvas') as HTMLCanvasElement;
            this.documentWidth = document.body.clientWidth * scale;
            this.documentHeight = document.body.clientHeight * scale;
            const header = document.querySelector('.fabric-header') as HTMLElement;
            const headerHeight = header.offsetHeight;
            const btns = document.querySelector('.fabric-btns') as HTMLElement;
            const btnsHeight = btns.offsetHeight;
            const canvasHeight = this.documentHeight - headerHeight - btnsHeight;
            console.log('this.documentWidth === ', this.documentWidth);
            console.log('canvasHeight === ', canvasHeight);
            const canvas = this.canvas = new fabric.Canvas(dom, {
                width: this.documentWidth,
                height: canvasHeight,
                containerClass: this.canvasContainerClass,
            });

            canvas.freeDrawingBrush = new fabric.EraserBrush(canvas);
            canvas.freeDrawingBrush.width = 10;

            // const backgroundImage = require('../../assets/images/canvas-background.jpg')
            // // backgroundImage.setOptions({mid: generateUUID()})
            // canvas.setBackgroundColor(new fabric.Pattern({source: backgroundImage, repeat: 'repeat'}), () => {
            //     console.log('canvas.setBackgroundColor callback');
            //     canvas.renderAll();
            // });


            canvas.setBackgroundColor("#fffffe", () => {
                canvas.renderAll();
            });

            fabric.Image.fromURL(require('../../assets/images/example2.png'), (oImg: fabric.Image) => {
                oImg.filters?.push(new fabric.Image.filters.Grayscale());
                oImg.applyFilters();
                const mid = generateUUID();
                oImg.setOptions({mid: mid});
                oImg.selectable = false;
                canvas.add(oImg.set({
                    left: 200,
                    top: 200,
                }));
                this.canvasShapes.push(oImg);
                const createdImage = {
                    mid: mid,
                    type: RectOperations.CREATE,
                    urlSmall: oImg.getSrc(),
                    createTime: new Date().getTime(),
                    prompt: "哈哈哈哈"
                } as CreatedImageData;
                this.createdImages.unshift(createdImage);
            });

            this.addRectControls();
            this.bindCanvasEvent();
            this.insertMode();
            this.canvasId=this.$route.query.id as string;
            let uid:string|null;
            let sid:string|null;
            if(configData.env===Envs.LOCAL){
              uid="admin";
              sid="admin"
            }else{
              uid=getCookie('uid');
              sid=getCookie('sid');
            }
            this.socketClient = new ReconnectSockJsClient(`${configData.socketUrl}?uid=${uid}&sid=${sid}&canvasId=${this.canvasId}`)
            this.socketClient.onmessagefun = this.clientOnmessage.bind(this);
            this.socketClient.connect()
            this.modifyCanvasPrototype();
        }
        modifyCanvasPrototype(){
          console.log('remove*****')
          // 1. 重新定义canvas的remove，如果remove的是当前active的对象，则先取消active的对象再进行remove。
          const _remove = this.canvas!.remove;
          // @ts-ignore
          this.canvas.remove = function (...objs:fabric.Object[]){
            let activeObj = this!.getActiveObject();
            if(activeObj&&objs){
              for (let obj of objs) {
                if(!obj){
                  continue;
                }
                if (obj.get('mid') === activeObj.get('mid')) {
                  this!.discardActiveObject();
                }
              }
            }
            return _remove.apply(this!, objs);
          }
        }

        clientOnmessage(e:any){
          const response = JSON.parse(e.data);
          const {messageType, msg} = response;
          const data = msg;
          if(messageType===ResponseMessageType.CONNECT){
            if(msg.type===ConnectMessageType.COOPERATION_LOGIN ||msg.type===ConnectMessageType.COOPERATION_HELLO) {
              if (!this.collaboratingUserInfos.find(user => user.uid === data.uid)) {
                this.collaboratingUserInfos?.push(data)
              }
            }else if(messageType===ConnectMessageType.COOPERATION_LOGOUT){
              let index = this.collaboratingUserInfos.findIndex((item)=>item.uid==data.uid);
              if(index>-1){
                this.collaboratingUserInfos.splice(index,1)
              }
            }

          }
        }

        //获取屏幕缩放比例
        getRatio() {
            var ratio = 0;
            var screen = window.screen;
            var ua = navigator.userAgent.toLowerCase();

            if (window.devicePixelRatio !== undefined) {
                ratio = window.devicePixelRatio;
            } else if (~ua.indexOf('msie')) {
                // @ts-ignore
                if (screen.deviceXDPI && screen.logicalXDPI) {
                    // @ts-ignore
                    ratio = screen.deviceXDPI / screen.logicalXDPI;
                }

            } else if (window.outerWidth !== undefined && window.innerWidth !== undefined) {
                ratio = window.outerWidth / window.innerWidth;
            }

            if (ratio) {
                ratio = Math.round(ratio * 100);
            }
            return ratio;
        }

        bindCanvasEvent() {
            this.historyManager = new FabricHistoryManager(this.canvas!, this);
            this.transferBoxManager = new TransferBoxManager(this.canvas!,this.historyManager)
            //鼠标按下事件
            this.canvas!.on("mouse:down", (e) => {
                console.log('mouse:down  this.canvasMode: ' + this.canvasMode + ' this.currentImageMid:' + this.currentImageMid + '  this.currentImageSelectable:' + this.currentImageSelectable);
                console.log('mouse:down  e.target: ', e.target);
                this.mouseDown = true;
                if (this.canvasMode !== CanvasMode.INSERT && this.canvasMode !== CanvasMode.SELECT) {
                    return;
                }
                // @ts-ignore
                if (this.currentImageMid != null && this.currentImageSelectable && e.target && this.currentImageMid == e.target['mid']) {
                    colorLog.red('selectMode --------------------')
                    this.switchCanvasMode(CanvasMode.SELECT);
                } else {
                    if (this.canvasMode == CanvasMode.SELECT) {
                        this.switchCanvasMode(CanvasMode.INSERT);
                    }
                    if (this.currentImageMid != null) {
                        // @ts-ignore
                        const o = this.getObjectByMId(this.canvas!, this.currentImageMid);
                        if (o != null) {
                            o.selectable = false;
                        }
                    }
                    this.currentImageMid = null;
                    this.currentImageSelectable = true;
                    this.hideImageSelectionBtn();
                }

                if (this.canvasMode === CanvasMode.INSERT) {
                    this.insertUUID = generateUUID();
                    this.removeEmptyRectAndSetUnSelectable();
                }

                if (e.pointer && (this.startCut || this.canvasMode === CanvasMode.INSERT)) {
                    this.currentSelectedRect = null;
                    this.clipArea = {
                        x: 0,
                        y: 0,
                        w: 0,
                        h: 0,
                    }
                    this.startCutX = e.pointer.x;
                    this.startCutY = e.pointer.y;
                    this.clipArea.x = e.pointer.x -= this.canvasRelativePan.x;
                    this.clipArea.y = e.pointer.y -= this.canvasRelativePan.y;
                    this.clipArea.w = 256;
                    this.clipArea.h = 256;
                    this.fill(this.clipArea.x, this.clipArea.y, this.clipArea.w, this.clipArea.h);
                }

            });

            this.canvas?.on("object:scaling",(e) => {
              if(!e.target) return;
              // console.log('object:scaling  e.target:', e.target);
              if(e.target.type === 'rect'){
                  const scalingRect = e.target as fabric.Rect;
                  this.limitObjectSize(scalingRect);
                  this.updateRectBtns();
                  if (this.currentImageMid == scalingRect['mid']) {
                      this.clipArea.x = scalingRect.left!;
                      this.clipArea.y = scalingRect.top!;
                      this.clipArea.w = parseInt(scalingRect.width! * scalingRect.scaleX! + '');
                      this.clipArea.h = parseInt(scalingRect.height! * scalingRect.scaleY! + '');
                  }
              }

              if(e.target.type === 'image'){
                  const scalingImage = e.target as fabric.Image;
                  this.limitObjectSize(scalingImage);
                  this.updateImageSelectionBtn();
              }

            })

            //鼠标抬起事件
            this.canvas!.on("mouse:up", (e) => {
                // this.canvas!.selection = true;
                // this.canvas!.setCursor("");
                this.mouseDown = false;
                this.canvas!.selection = false;

                if (this.startCut) {
                    this.generateImageDataByCutRect(this.cutUUID);
                }
                if (this.canvasMode === CanvasMode.INSERT) {
                    this.showRectBtn();
                    // if (this.clipArea.w < 10 || this.clipArea.h < 10) {
                    //     const cutObject = this.getObjectByMId(this.canvas!, this.insertUUID);
                    //     console.log('cutObject === ', cutObject);
                    //     // @ts-ignore
                    //     // this.canvas!.remove(cutObject).renderAll();
                    //     this.canvas!.remove(cutObject);
                    // } else {
                    //     this.showRectBtn();
                    // }
                }
                this.hideGeneratedImagesContainer();
            });

            //鼠标移动
            this.canvas!.on('mouse:move', (e) => {
                // console.log('mouse:move  this.canvasMode === ', this.canvasMode)
                if (this.canvasMode === CanvasMode.DRAGGING && this.mouseDown && e && e.pointer) {
                    console.log('canvas dragging ************** ');
                    // console.log('canvas  move this.canvas:', this.canvas);
                    // const x = e.pointer.x;
                    // const y = e.pointer.y;
                    var delta = new fabric.Point(e.e.movementX, e.e.movementY);
                    this.canvasRelativePan.x += e.e.movementX;
                    this.canvasRelativePan.y += e.e.movementY;
                    this.canvas!.relativePan(delta);
                }
                // if (!this.isDrawingMode && this.mouseDown && e.pointer && (this.startCut || this.canvasMode === 'insertMode')) {
                //     this.fill(this.startCutX, this.startCutY, e.pointer.x - this.startCutX, e.pointer.y - this.startCutY);
                // }

                // if (this.canvasMode === "selectMode" && e.target && this.currentImageMid == e.target['mid'] && this.currentImageSelectable) {
                //     console.log('object moving ************** ');
                //     this.clipArea.x += e.e.movementX;
                //     this.clipArea.y += e.e.movementY;
                // }
            });

            /**
             * 移动事件
             */
            this.canvas!.on('object:moving', (event) => {
                // colorLog.blue('object:moving  event ==== ', event);
                // if (event.target instanceof fabric.Rect) {
                if (this.canvasMode === CanvasMode.SELECT && event.target && this.currentImageMid == event.target['mid'] && this.currentImageSelectable) {
                    // console.log('object moving ************** ');
                    this.clipArea.x = event.target.left!;
                    this.clipArea.y = event.target.top!;
                }
                if (event.target && event.pointer) {
                    const x = event.pointer.x;
                    const y = event.pointer.y;
                    if (Math.abs(this.currentPointY - y) < 5 && Math.abs(this.currentPointX - x) < 2) {
                        return;
                    }
                    const scale = window.devicePixelRatio;
                    const documentWidth = this.documentWidth;
                    // colorLog.blue('object:moving  documentWidth - 50 ==== ', documentWidth - 50);
                    const canvasRect = document.querySelector('#canvas')!.getBoundingClientRect();
                    // colorLog.blue('object:moving  canvasRect ==== ', canvasRect);
                    const targetRect = event.target.getBoundingRect();
                    // colorLog.blue('object:moving  targetRect ==== ', targetRect);
                    let rectLeft = targetRect.left;
                    if(event.target instanceof fabric.Rect||this.transferBoxManager?.isTransferBox(event.target)) {
                      this.updateRectBtns();
                    }
                    if(event.target.type === 'image'){
                      this.updateImageSelectionBtn();
                    }
                    const fabricElement = document.querySelector(`.${this.canvasScrollerClass}`) as Element;
                    let fabricScrollTop = fabricElement.scrollTop;
                    let fabricScrollLeft = fabricElement.scrollLeft;
                    // colorLog.blue('object:moving  fabricElement.getBoundingClientRect() ==== ', fabricElement.getBoundingClientRect());
                    // colorLog.blue('object:moving  fabricScrollTop ==== ', fabricScrollTop);
                    // colorLog.blue('object:moving  fabricScrollLeft ==== ', fabricScrollLeft);
                    if (x < this.currentPointX) {
                        // 向左滑动
                        if (rectLeft - fabricScrollLeft < 50) {
                            // 需判断画布宽度，看是否需要增加宽度
                            if (fabricScrollLeft === 0) {
                                // let addWidth = 50 - rectLeft;
                                let addWidth = this.canvasMoveSpeed;
                                if (fabricScrollLeft < addWidth) {
                                    let newCanvasWith = this.canvas!.getWidth() + addWidth;
                                    this.canvas!.setWidth(newCanvasWith);
                                }
                                // 画布需要向后移动
                                let delta = new fabric.Point(addWidth, 0);
                                this.canvasRelativePan.x += addWidth;
                                this.canvas!.relativePan(delta);
                            } else {
                                // 向左滑动滚动条
                                fabricElement.scrollLeft = rectLeft - 50;
                            }
                        }
                    } else {
                        // 向右滑动
                        let maxRight = (targetRect.left + targetRect.width - fabricScrollLeft) * scale;
                        // console.log('向右滑动  maxRight === ' + maxRight)
                        // console.log('向右滑动  documentWidth - 50 === ' + (documentWidth - 50))
                        if (maxRight > documentWidth - 50) {
                            // 需判断画布宽度，看是否需要增加宽度
                            // let addWidth = maxRight - (documentWidth - 50);
                            let addWidth = this.canvasMoveSpeed;
                            // addWidth *= scale;
                            // console.log('addWidth === ', addWidth);
                            // console.log('向右滑动 111   addWidth === ' + addWidth);
                            const canvasRight = (canvasRect.width + canvasRect.x);
                            // console.log('向右滑动 111   canvasRight === ' + canvasRight);
                            let newCanvasWith = this.canvas!.getWidth() + addWidth;
                            this.canvas!.setWidth(newCanvasWith);

                            // 画布向前移动
                            let delta = new fabric.Point(-addWidth, 0);
                            this.canvasRelativePan.x -= addWidth;
                            this.canvas!.relativePan(delta);
                        }
                    }

                    let rectTop = targetRect.top;
                    if (y < this.currentPointY) {
                        // 向上滑动
                        if (rectTop - fabricScrollTop < 50) {
                            // 需判断画布高度，看是否需要增加高度
                            if (fabricScrollTop === 0) {
                                // let addHeight = 50 - rectTop;
                                let addHeight = this.canvasMoveSpeed;
                                let newCanvasHeight = this.canvas!.getHeight() + addHeight;
                                this.canvas!.setHeight(newCanvasHeight);

                                // 画布向下移动
                                let delta = new fabric.Point(0, addHeight);
                                this.canvasRelativePan.y += addHeight;
                                this.canvas!.relativePan(delta);
                            } else {
                                // 滑动滚动条
                                fabricElement.scrollTop = rectTop - 50;
                            }
                        }
                    } else {
                        const header = document.querySelector('.fabric-header') as HTMLElement;
                        const headerHeight = header.offsetHeight;
                        const btns = document.querySelector('.fabric-btns') as HTMLElement;
                        const btnsHeight = btns.offsetHeight;
                        const documentHeight = this.documentHeight - headerHeight - btnsHeight;
                        // console.log('向下滑动 fabricScrollTop:' + fabricScrollTop + '  targetRect:', targetRect);
                        let maxBottom = (targetRect.top + targetRect.height - fabricScrollTop) * scale;
                        // console.log('向下滑动  maxBottom == ' + maxBottom + '  documentHeight == ' + documentHeight);
                        if (maxBottom > (documentHeight - 55)) {
                            // let addHeight = targetRect.top + targetRect.height - this.documentHeight - fabricScrollTop;
                            // let addHeight = maxBottom - (documentHeight - 55);
                            let addHeight = this.canvasMoveSpeed;
                            // console.log('向下滑动  addHeight ==== ' + addHeight)
                            let newCanvasHeight = this.canvas!.getHeight() + addHeight;
                            this.canvas!.setHeight(newCanvasHeight);
                            let delta = new fabric.Point(0, -addHeight);
                            this.canvasRelativePan.y -= addHeight;
                            this.canvas!.relativePan(delta);
                            // if (canvasRect.height + canvasRect.y < this.documentHeight) {
                            // } else {
                            //     fabricElement.scrollTop = fabricElement.scrollTop + addHeight;
                            // }
                        }
                    }

                    this.currentPointX = x;
                    this.currentPointY = y;
                }
            })

            /**
             * 选中事件
             */
            this.canvas!.on('selection:updated', (event) => {
                // colorLog.blue('selection:updated  event ==== ', event);
                if (event.selected && (event.selected[0] instanceof fabric.Rect||this.transferBoxManager?.isTransferBox(event.selected[0]))) {
                  this.currentSelectedDeletableObject = this.currentSelectedRect = event.selected[0];
                  this.canvas!.setActiveObject(this.currentSelectedRect);
                  this.showRectBtn();
                } else {
                    this.hideRectBtn();
                }
            })

            this.canvas!.on('selection:created', (event) => {
                // colorLog.blue('selection:created  event ==== ', event);
                if (event.selected && (event.selected[0] instanceof fabric.Rect||this.transferBoxManager?.isTransferBox(event.selected[0]))) {
                  this.currentSelectedDeletableObject =  this.currentSelectedRect = event.selected[0];
                  this.canvas!.setActiveObject(this.currentSelectedRect);
                    this.showRectBtn();
                } else {
                    this.hideRectBtn();
                }
            })

            this.canvas!.on('selection:cleared', (event) => {
                colorLog.blue('selection:cleared  event ==== ', event);
                if (this.canvasMode === CanvasMode.ERASER_BRUSH) {
                    // 需要将当前框active
                    console.log('selection:cleared  this.currentSelectedRect:', this.currentSelectedRect);
                    this.canvas!.setActiveObject(this.currentSelectedRect!);
                    return;
                }

                this.hideRectBtn();
                this.removeEmptyRectAndSetUnSelectable();
            })
            this.canvas!.on('erasing:end', (event) => {
              this.activeIfExistTransferBox();
            })

            // // 鼠标滚动事件
            // this.canvas!.on('mouse:wheel', (event) => {
            //     console.log('mouse:wheel event === ', event);
            //     if (event.e) {
            //         const wheelEvent = event.e as WheelEvent;
            //         const offset = wheelEvent.deltaY / 5;
            //         let delta = new fabric.Point(0, -offset);
            //         this.canvas!.relativePan(delta);
            //         this.canvasRelativePan.y -= offset;
            //         this.drawVerticalScrollBar();
            //     }
            // })
        }

        limitObjectSize(scalingObj: fabric.Rect | fabric.Image) {
            scalingObj.flipX = false;
            scalingObj.flipY = false
            scalingObj.lockScalingFlip = true;
            // scalingObj.lockMovementX = true;
            // scalingObj.lockMovementY = true;
            if (scalingObj.scaleX) {
                if (scalingObj.scaleX * scalingObj.width! < this.objectMinWidth) {
                    scalingObj.scaleX = this.objectMinWidth / scalingObj.width!;
                }
                if (scalingObj.scaleX * scalingObj.width! > this.objectMaxWidth) {
                    scalingObj.scaleX = this.objectMaxWidth / scalingObj.width!;
                }
            }
            if (scalingObj.scaleY) {
                if (scalingObj.scaleY * scalingObj.height! < this.objectMinWidth) {
                    scalingObj.scaleY = this.objectMinWidth / scalingObj.height!;
                }
                if (scalingObj.scaleY * scalingObj.height! > this.objectMaxWidth) {
                    scalingObj.scaleY = this.objectMaxWidth / scalingObj.height!;
                }
            }
        }

        drawVerticalScrollBar() {
            // 确定画布最上方和最下方的元素
            console.log('drawVerticalScrollBar  this.canvasRelativePan.y === ', this.canvasRelativePan.y);
            if (this.canvasRelativePan.y < 0) {
            }
        }

        drawHorizontalScrollBar() {
        }

        removeEmptyRectAndSetUnSelectable() {
            this.canvas!.getObjects().forEach((object) => {
                if (this.transferBoxManager?.isTransferBox(object)){
                    console.log('it is a transferBox .')
                }else if (object instanceof fabric.Rect) {
                    const mid = object['mid'];
                    console.log('removeEmptyRectAndSetUnSelectable mid:' + mid + '  this.insertUUID:' + this.insertUUID + '  this.drawingRectMidMap:', this.drawingRectMidMap);
                    if (this.insertUUID !== mid && !this.drawingRectMidMap.hasOwnProperty(mid)) {
                        // console.log('removeEmptyRectAndSetUnSelectable left === ', object.left);
                        // console.log('removeEmptyRectAndSetUnSelectable top === ', object.top);
                        // console.log('removeEmptyRectAndSetUnSelectable mid === ', mid);
                        this.canvas!.remove(object);
                    }
                } else {
                    object.selectable = false;
                    object.moveCursor = 'default';
                    object.hoverCursor = 'default';
                }
            });
        }

        activeIfExistTransferBox(){
          let box = this.canvas!.getObjects().find(obj => this.transferBoxManager?.isTransferBox(obj)&&!this.transferBoxManager?.isTransferring(obj));
          console.log(box)
          if(box){
            this.canvas?.setActiveObject(box);
          }else{
            console.log('***box')
          }
        }
        //是否存在处于transfer绘画中的对象（点了确定之后已经终止绘画的不算）。
        existTransferBox(){
          return this.canvas!.getObjects().some(obj => this.transferBoxManager?.isTransferBox(obj)&&!this.transferBoxManager?.isTransferring(obj));
        }

        generateImageDataByCutRect(objUUID: string) {
            console.log('generateImageDataByCutRect this.clipArea === ', this.clipArea);
            console.log('generateImageDataByCutRect this.canvasRelativePan == ', this.canvasRelativePan);
            // 先删掉边框，否则传递给模型的图片会携带边框
            this.canvas!.discardActiveObject();
            const cutObject = this.getObjectByMId(this.canvas!, objUUID)!;
            this.canvas!.remove(cutObject).renderAll();

            const canvasContext = this.canvas!.getContext();
            const scale = window.devicePixelRatio;
            let x = (this.clipArea.x + this.canvasRelativePan.x) * scale;
            let y = (this.clipArea.y + this.canvasRelativePan.y) * scale;
            var data = canvasContext.getImageData(x, y, this.clipArea.w * scale, this.clipArea.h * scale);

            var canvas = document.createElement("canvas");
            canvas.height = this.clipArea.h * scale;
            canvas.width = this.clipArea.w * scale;

            const context = canvas.getContext("2d");
            context!.putImageData(data, 0, 0);
            this.cutImgSrc = canvas.toDataURL("image/png", 1);

            // 再将边框添加回来
            this.fill(this.clipArea.x, this.clipArea.y, this.clipArea.w, this.clipArea.h);
        }
        // 对指定区域截图。
        makeScreenShot(clipArea:{x:number,y:number,w:number,h:number}){
          const canvasContext = this.canvas!.getContext();
          const scale = window.devicePixelRatio;
          var data = canvasContext.getImageData(clipArea.x * scale, clipArea.y * scale, clipArea.w * scale, clipArea.h * scale);

          var canvas = document.createElement("canvas");
          canvas.height = clipArea.h * scale;
          canvas.width = clipArea.w * scale;

          const context = canvas.getContext("2d");
          context!.putImageData(data, 0, 0);
          let base64 = canvas.toDataURL("image/png", 1);
          return base64;
        }
        // 对矩形进行截图。
        makeRectScreenShot(rect:fabric.Rect){
          let width = rect.strokeWidth ? rect.strokeWidth : 0;
          console.log('width:'+width)
          let clipArea = {
            x: rect.left! + width * 2,
            y: rect.top! + width * 2,
            w: rect.width! - width * 4,
            h: rect.height! - width * 4
          }
          let activeObject = this.canvas?.getActiveObject();
          // 取消选中状态
          this.canvas!.discardActiveObject();
          // 让取消选中状态生效。
          this.canvas!.renderAll();
          if(activeObject){
            this.canvas?.setActiveObject(activeObject);
          }
          // 开始截图。
          return this.makeScreenShot(clipArea);
        }

        hideAllTools(){
          [this.$refs.rectButtonsRef,this.$refs.rectDeleteBtnRef,this.$refs.imageMoreBtnRef,this.$refs.generatedImagesRef].forEach(
              ref => ref.api.hide()
          )
        }

        showRectBtn() {
          if (!this.currentSelectedRect) return;
          this.$refs.rectButtonsRef.api.show();
          this.$refs.rectDeleteBtnRef.api.show();
        }

        updateRectBtns(){
          this.$refs.rectButtonsRef.api.update();
          this.$refs.rectDeleteBtnRef.api.update();
        }

        hideRectBtn() {
            // if (this.canvasMode === CanvasMode.ERASER_BRUSH) {
            //     return
            // }
            // const rectBtns = document.querySelector('#rectBtns') as HTMLElement;
            // rectBtns.style.display = 'none';
            this.$refs.rectButtonsRef.api.hide();
            this.$refs.rectDeleteBtnRef.api.hide();
        }

        showImageSelectionBtn(image: fabric.Image) {
          this.currentSelectedImage = image;
          this.$refs.imageMoreBtnRef.api.show();
          this.$refs.rectDeleteBtnRef.api.show();
        }
        updateImageSelectionBtn(){
          this.$refs.imageMoreBtnRef.api.update();
          this.$refs.rectDeleteBtnRef.api.update();
          this.hideGeneratedImagesContainer();
        }

        hideImageSelectionBtn() {
          this.$refs.imageMoreBtnRef.api.hide();
          this.$refs.rectDeleteBtnRef.api.hide();
          this.hideGeneratedImagesContainer();
        }

        toggleGeneratedImagesContainer(){
          const visible = this.$refs.generatedImagesRef.api.getVisible()
          if(visible){
            this.hideGeneratedImagesContainer();
          }else{
            this.showGeneratedImagesContainer();
          }
        }

        showGeneratedImagesContainer(){
          this.$refs.generatedImagesRef.api.show();
        }
        updateGeneratedImagesContainer(){
          this.$refs.generatedImagesRef.api.update();
        }
        hideGeneratedImagesContainer(){
          this.$refs.generatedImagesRef.api.hide();
        }

        handleGeneratedImageClicked() {
            this.hideImageSelectionBtn();
        }

        handleGeneratedImageMouseenter(event:MouseEvent, imgData:CreationData){
          this.resetCurrentSelectedImage(imgData);
        }

        resetCurrentSelectedImage(imgData:CreationData){
          const oldImage = this.getObjectByMId(this.canvas!, this.currentImageMid!) as fabric.Image;
          // const oldImage = this.currentSelectedImage as fabric.Image;
          const mid = oldImage['mid'];
          const {left, top} = oldImage;
          const width = oldImage.getScaledWidth();
          const height = oldImage.getScaledWidth();
          this.canvas!.remove(oldImage).renderAll();
          this.canvas!.remove(this.currentSelectedImage!);

          fabric.Image.fromURL(imgData.urlSmall, (oImg: fabric.Image) => {
            oImg.applyFilters();
            oImg.setOptions({mid: mid});
            this.canvas!.add(oImg.set({
              left,
              top,
            }));

            const ratioX = width! / oImg.getScaledWidth();
            const ratioY = height! / oImg.getScaledHeight();
            oImg.set({
              scaleX: ratioX,
              scaleY: ratioY,
            });

            if (imgData.type === RectOperations.INPAINT) {
              console.log('resetCurrentSelectedImage  inpaint')
              oImg.sendToBack();
            }

            if (mid === this.currentImageMid && this.currentImageSelectable) {
              oImg.selectable = true;
              // oImg.hoverCursor = 'pointer';
              // oImg.moveCursor = 'pointer';
            } else {
              oImg.selectable = false;
            }
            this.currentSelectedDeletableObject = oImg;
            this.showImageSelectionBtn(oImg);

            // 更新图层的图片
            for (const imageData of this.createdImages) {
                if (imageData.mid === mid) {
                    imageData.urlSmall = oImg.getSrc();
                    break;
                }
            }

          })
        }

        cut() {
            this.startCut = true;
            this.canvas!.selection = false;
            this.canvas!.getObjects().forEach((object) => {
                object.selectable = false;
            });
        }

        cancelCut() {
            this.startCut = false;
            this.cutImgSrc = '';
            this.canvas!.selection = true;
            this.canvas!.getObjects().forEach((object) => {
                object.selectable = true;
            });
        }

        fill(x
                 :
                 number, y
                 :
                 number, w
                 :
                 number, h
                 :
                 number
        ) {
            if (w < 0) {
                // 从右往左滑
                w = -w;
                x = x - w;
            }

            if (h < 0) {
                h = -h;
                y = y - h;
            }
            if (this.canvasMode === CanvasMode.INSERT) {
                const cutObject = this.getObjectByMId(this.canvas!, this.insertUUID);
                // @ts-ignore
                this.canvas!.remove(cutObject);
            }

            if (this.startCut) {
                const cutObject = this.getObjectByMId(this.canvas!, this.cutUUID);
                // @ts-ignore
                this.canvas!.remove(cutObject);
            }

            const rect = new fabric.Rect({
                left: x,
                top: y,
                fill: 'rgb(255,255,255,0)',
                width: w,
                height: h,
                stroke: '#000000',
                strokeWidth: 1,
                erasable: false
            });
            rect.selectable = false;
            if (this.startCut) {
                rect.setOptions({mid: this.cutUUID});
            } else {
                rect.setOptions({mid: this.insertUUID});
            }
            this.canvas!.add(rect);
            if (this.canvasMode === CanvasMode.INSERT) {
                rect.hoverCursor = 'move';
                rect.moveCursor = 'move';
                this.canvas!.setActiveObject(rect);
                this.currentImageMid = this.insertUUID;
                this.currentImageSelectable = true;
            }
            console.log('fill *********   this.currentImageMid === ', this.currentImageMid)
            this.currentSelectedRect = rect;
            this.clipArea = {
                x,
                y,
                w,
                h
            };
            console.log('fill *********  this.clipArea === ', this.clipArea);
        }

        imageLayerImageClick(imageData: CreatedImageData) {
            this.insertUUID = '';
            this.canvas!.discardActiveObject();
            this.removeEmptyRectAndSetUnSelectable();
            this.hideRectBtn();
            const image = this.getObjectByMId(this.canvas!, imageData.mid);
            if (image != null) {
                this.currentImageMid = imageData.mid;
                this.currentSelectedDeletableObject = image;
                if (this.imageLayerShowHide[imageData.mid] === false) {
                    return;
                }
                if (imageData.type === RectOperations.CREATE || imageData.type === RectOperations.TRANSFER) {
                    image.selectable = true;
                    image.hoverCursor = 'move';
                    image.moveCursor = 'move';
                    this.currentImageSelectable = true;
                    this.canvas!.setActiveObject(image);
                } else {
                    image.selectable = false;
                    this.currentImageSelectable = false;
                    this.canvas!.discardActiveObject();
                }
                this.canvas!.renderAll();
                this.imageSelectionDatas = this.imageSelectionBackup[imageData.mid];
                this.showImageSelectionBtn(image as fabric.Image);
            }
            console.log('imageLayerImageClick  this.currentImageMid === ', this.currentImageMid);
        }

        imageLayerShowHideClick (imageData: CreatedImageData) {
            const image = this.getObjectByMId(this.canvas!, imageData.mid);
            if (this.imageLayerShowHide[imageData.mid] === false) {
                this.imageLayerShowHide[imageData.mid] = true;
                image!.opacity = 1;
                if (imageData.mid === this.currentImageMid) {
                    this.imageLayerImageClick(imageData);
                }
            } else {
                this.imageLayerShowHide[imageData.mid] = false;
                if (imageData.mid === this.currentImageMid) {
                    this.canvas!.discardActiveObject();
                    this.hideRectBtn();
                    this.hideImageSelectionBtn();
                }
                image!.opacity = 0;
            }
            this.canvas!.renderAll();
            console.log('imageLayerShowHideClick  image === ', image);
        }

        getObjectByMId(canvas
                           :
                           fabric.Canvas, mid
                           :
                           string
        ) {
            // @ts-ignore
            return canvas.getObjects().find(a => a['mid'] == mid);
        }

        downloadCutImage() {
            var blob = this.dataURLtoBlob(this.cutImgSrc);
            var objurl = URL.createObjectURL(blob);
            var link = document.createElement("a");
            link.download = new Date().getTime() + ".png";
            link.href = objurl;
            link.click();
        }

        downloadCanvas() {
            var imgData = this.canvas!.toDataURL({
                format: 'png',
                multiplier: 4
            });
            var blob = this.dataURLtoBlob(imgData);
            var objurl = URL.createObjectURL(blob);
            var link = document.createElement("a");
            link.download = new Date().getTime() + ".png";
            link.href = objurl;
            link.click();
        }

        dataURLtoBlob(dataurl
                          :
                          string
        ) {
            var parts = dataurl.split(',');
            let mime = parts[0]!.match(/:(.*?);/)![1]
            if (parts[0].indexOf('base64') !== -1) {
                var bstr = atob(parts[1]), n = bstr.length, u8arr = new Uint8Array(n)
                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n)
                }

                return new Blob([u8arr], {type: mime})
            } else {
                var raw = decodeURIComponent(parts[1])
                return new Blob([raw], {type: mime})
            }
        }

        moveToRight() {
            this.canvasShapes.forEach((shape: fabric.Object) => {
                shape.set({left: shape.left! + 20});
            })
            this.canvas?.renderAll();
        }

        setRectZIndex() {
            this.canvasShapes.forEach((shape: fabric.Object) => {
                if (shape instanceof fabric.Rect) {
                    shape.moveTo(4);
                }
            })
        }

        setImageSize() {
            this.canvasShapes.forEach((shape: fabric.Object) => {
                if (shape instanceof fabric.Image) {
                    const ratioX = 100 / shape.getScaledWidth();
                    const ratioY = 100 / shape.getScaledHeight();
                    shape.set({

                        scaleX: ratioX * (shape.scaleX ?? 0),
                        scaleY: ratioY * (shape.scaleY ?? 0)
                    });
                }

            })
            this.canvas?.renderAll();
        }
    }
