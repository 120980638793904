
import {Vue, Options} from 'vue-class-component';
import anime from "animejs";

@Options<BounceTransitionGroup>({
  components: {},
  props: {}
})
export default class BounceTransitionGroup extends Vue {
  onBeforeEnter(el:Element){

  }
  onEnter(el:Element,done:() => void){
    anime({
      targets:el,
      opacity:[.6,1],
      scale:[.2,1],
      complete:() => done()
    });
  }
  onLeave(el:Element,done:() => void){
    anime({
      targets:el,
      opacity:.6,
      scale:0,
      easing:'easeOutCubic',
      complete:() => done()
    });
  }
}
