
import {Options, Vue} from 'vue-class-component';

@Options<MetaDialog>({
  components: {},
  emits:['beforeClose','update:modelValue','opened','closed'],
  props: {
    modelValue:{
      required:true,
      type:Boolean
    },
    title:{
      required:false,
      type:String,
      default:''
    }
  },
  watch:{
    modelValue:{
      handler(newVal:boolean){
        this.visible = newVal;
      },
      immediate:true
    },
    visible(newVal:boolean){
      this.$emit('update:modelValue',newVal);
    }
  }

})
export default class MetaDialog extends Vue {
  modelValue!:boolean;
  visible = false;
  title!:string;
}
