import {fabric} from "@/assets/lib/fabric";
import {ICollection} from "@/assets/lib/fabric/fabric-impl";
import {generateUUID} from "@/utils";

export function getObjectByMId(canvas:fabric.ICollection<object>,mid:string):fabric.Object{
    // @ts-ignore
    return canvas.getObjects().find(a => a['mid'] == mid);
}

export function generateObjectMidIfNotExist(object:fabric.Object){
    // @ts-ignore
    if (object && !object.get('mid')) {
        object.setOptions({mid: generateUUID()});
    }
}
export function setObjectMid(object:fabric.Object,mid:string){
    // @ts-ignore
    if (object && !object.get('mid')) {
        object.setOptions({mid: mid});
    }
}