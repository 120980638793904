export enum DRAWING_BRUSH_MODE{
    NONE = 0 ,//没有画笔
    NORMAL_BRUSH = 1,//普通画笔模式
    TRANSFER_BRUSH = 2,//transfer画笔模式。
}

//所有类型的canvasMode
export enum CanvasMode{
    DRAWING='drawing',
    TRANSFER_DRAWING='transferDrawing',
    ERASER_BRUSH='eraserBrushMode',
    INSERT='insertMode',
    DRAGGING ='dragging',
    SELECT='selectMode',
}
//需要记录的修改类型的canvasMode，用于判断是否能够从某种模式切换到另一种模式（不需要记录的比如橡皮擦模式，橡皮擦模式可以切换到任意模式，任意模式也可以切换到橡皮擦模式）
export const NEED_RECORD_MODIFY_CANVAS_MODE = new Set([CanvasMode.INSERT, CanvasMode.TRANSFER_DRAWING, CanvasMode.DRAWING]);