
import {Options, Vue} from 'vue-class-component';
import MetaPopper from "@/components/MetaPopper.vue";

import {PropType} from "vue";
import {CanvasCollaborationAccessType} from "@/types/api/Common";
import {colorLog} from "@/utils";

@Options<CanvasPrivilegeSetting>({
  components: {MetaPopper},
  props: {
    modelValue:{
      required:false,
      type:[Number,null] as PropType<CanvasCollaborationAccessType | null>,
      default:CanvasCollaborationAccessType.READ_ONLY
    },
    popperVisible:{
      required:false,
      type:Boolean,
      default:false
    }
  },
  computed:{
    mainBtnTextPath(){
      switch (this.accessType){
        case CanvasCollaborationAccessType.CAN_EDIT:
          return 'canEdit';
        case CanvasCollaborationAccessType.READ_ONLY:
          return 'canView';
        default:
          return 'error';
      }
    }
  },
  watch:{
    modelValue:{
      handler(newValue:CanvasCollaborationAccessType){
        this.accessType = newValue;
      },
      immediate: true
    },
    popperVisible:{
     handler(newVal:boolean){
       this.settingPopperVisible = newVal;
     },
     immediate:true
    },
    settingPopperVisible(newVal:boolean){
      this.$emit('update:',newVal);
    },
    accessType(newVal:CanvasCollaborationAccessType){
      this.$emit('update:modelValue',newVal);
    }
  }
})
export default class CanvasPrivilegeSetting extends Vue {
  CanvasCollaborationAccessType = CanvasCollaborationAccessType;
  settingPopperVisible = false;
  accessType:CanvasCollaborationAccessType | null = CanvasCollaborationAccessType.READ_ONLY;
  mainBtnTextPath!:string;
  $refs!:{
    settingPopper:MetaPopper
  }
  
  async handleMainBtnClicked(){
    this.settingPopperVisible = !this.settingPopperVisible;
    if(this.settingPopperVisible){
      await this.$nextTick();
      this.$refs.settingPopper.popperInstance?.update();
    }
  }

  handleSettingOptionClicked(accessType:CanvasCollaborationAccessType){
    if(this.accessType !== accessType){
      this.$emit('change',accessType);
    }
    this.accessType = accessType;
  }
}
