import {Vue} from "vue-class-component";
import {fabric} from '@/assets/lib/fabric';
import {generateObjectMidIfNotExist, getObjectByMId} from "@/views/test/FabricUtil";
import {generateUUID} from "@/utils";
export const HISTORY_IGNORE_ADD_KEY='historyAddNotRecord';
export class FabricHistoryManager {
    canvas:fabric.Canvas;
    vue:Vue;

    commands:Array<Command> = [];
    index = 0;
    //用于储存modify前object的属性。
    objectStatePropertyBeforeModify:any={};

    //是否临时冻结对于change的监听
    freezeChangeListenBeforeUndoRedo: boolean = false;
    constructor(canvas:fabric.Canvas,vue:Vue){
        this.canvas = canvas;
        this.vue = vue;
        this.initListener();
    }

    initListener():void{
        this.canvas.on('mouse:down',(e)=>{
            let object = e.target;
            console.log('mouse:down')
            console.log(this.canvas.getActiveObject())
            // console.log(object)
            let properties = getObjectProperty(object);
            if (properties != null) {
                this.objectStatePropertyBeforeModify = properties;
            }
        })
        this.canvas.on('mouse:up',(e)=>{
            // let block = e.target;
            // console.log('mouse:up')
            // console.log(block)
            this.objectStatePropertyBeforeModify = {};
        })
        this.canvas.on("object:added", (e) =>{
            const object = e.target as any;
            console.log('object:added',object);
            console.log(this.canvas.getActiveObject())
            if (this.freezeChangeListenBeforeUndoRedo) {
                // console.log('change not record')
                return;
            }
            // 如果该object没有指定mid，则为其指定mid。
            generateObjectMidIfNotExist(object)
            this.delayGenerateHistoryCommand(object);
        });

        this.canvas.on("object:modified", (e) =>{
            const object = e.target as any;
            console.log('object:modified',object,this.canvas.getActiveObject());
            // 元素的modified的change的undo、redo似乎不会再次触发object:modified
            if (this.freezeChangeListenBeforeUndoRedo) {
                // console.log('change not record')
                return;
            }
            // console.log(getObjectProperty(object),Object.assign({},this.objectStatePropertyBeforeModify),object._stateProperties)
            this.addOperationAsCommand(new ModifyOperation(object,Object.assign({},this.objectStatePropertyBeforeModify)),this.canvas.getActiveObject());
        });
        this.canvas.on("object:removed", (e) =>{
            const object = e.target as any;
            console.log('object:removed',object,this.canvas.getActiveObject());
            if (this.freezeChangeListenBeforeUndoRedo) {
                // console.log('change not record')
                return;
            }
            this.addOperationAsCommand(new RemoveOperation(object,this.canvas),this.canvas.getActiveObject());
        });

        this.canvas.on("erasing:end",  (e) =>{
            const {targets} = e as any;
            // console.log('erasing:end')
            if (!targets || targets.length == 0) {
                return;
            }
            if (this.freezeChangeListenBeforeUndoRedo) {
                // console.log('change not record')
                return;
            }
            this.addOperationAsCommand(new EraserOperation(targets, this.canvas),this.canvas.getActiveObject());
        });
    }
    addOperationAsCommand(operation:Operation,activeObject:fabric.Object|null=null){
        this.addCommand(new Command([operation],activeObject?activeObject.mid:null));
    }
    addCommand(command:Command){
        // console.log(`this.index:${this.index},this.commands.length:${this.commands.length}`)
        if (this.commands.length) {
            this.commands.splice(this.index, this.commands.length - this.index);
        }
        this.commands.push(command);
        this.index++;
        // console.log('index+1:' + this.index)
        return this;
    }
    clear() {
        this.commands = []
        this.index = 0;
        return this;
    }

    freezeListener(){
        this.freezeChangeListenBeforeUndoRedo = true;
    }
    unFreezeListener(){
        this.freezeChangeListenBeforeUndoRedo = false;
    }

    doSomethingFreezeChangeListener(func:Function){
        this.freezeChangeListenBeforeUndoRedo = true;
        func();
        this.freezeChangeListenBeforeUndoRedo = false;
    }



    undo() {
        // console.log('undo***')
        if (this.index > 0) {
            this.freezeChangeListenBeforeUndoRedo = true;
            // console.log('real undo***')
            let command = this.commands[--this.index];
            command.undo();

            // 激活当时激活的对象。
            if(this.index>0){
                this.restoreCommandActiveObject(this.commands[this.index - 1]);
            }else{
                this.canvas.discardActiveObject();
            }

            this.canvas.renderAll();
            this.freezeChangeListenBeforeUndoRedo = false;
            return true;
        }
        return false;
    }

    redo() {
        // console.log('redo***')
        if (this.index < this.commands.length) {
            this.freezeChangeListenBeforeUndoRedo = true;
            // console.log('real redo***')
            let command = this.commands[this.index++];
            command.execute();
            // 激活当时激活的对象。
            this.restoreCommandActiveObject(command);
            this.canvas.renderAll();
            this.freezeChangeListenBeforeUndoRedo = false;
            // console.log(`this.index:${this.index}`)
            return true;
        }
        return false;
    }
    private  restoreCommandActiveObject(command:Command){
        if(command.activeMIdAfterExecute){
            let object = getObjectByMId(this.canvas,command.activeMIdAfterExecute);
            if(object){
                this.canvas.setActiveObject(object);
            }
        }else{
            this.canvas.discardActiveObject();
        }
    }
    popHistory(){
         if(typeof this.commands.pop() !== 'undefined'){
             this.index--;
         }
    }

    /**
     * 由于一个物体刚生成时可能会有随之而来的一系列操作，比如
     * @param object
     * @private
     */
    private delayGenerateHistoryCommand(object: any) {
        setTimeout(()=>{
            if(object.get(HISTORY_IGNORE_ADD_KEY)){
                //如果该object表示，需要忽略当前object的新增（比如它可能马上就会被删除），则不对其进行记录。
                object.set(HISTORY_IGNORE_ADD_KEY,false);
                return
            }
            this.addOperationAsCommand(new AddOperation(object,this.canvas),this.canvas.getActiveObject());
        },100);
    }
    markObjectAddIgnore(object: any,ignoreAddListen:boolean=true){
        object.set(HISTORY_IGNORE_ADD_KEY,ignoreAddListen)
    }
}
function getObjectProperty(object:any):any{
    let res = {};
    if (!object || !object.stateProperties){
        return null;
    }
    for (let p of object.stateProperties) {
        // @ts-ignore
        res[p] = object[p];
    }
    return res;
}
export class Command{
    id:string=generateUUID();
    ops:Array<Operation>=[];
    activeMIdAfterExecute:string|null=null;
    constructor(ops:Array<Operation>,activeMIdAfterExecute:string|null=null) {
        this.ops = ops;
        this.activeMIdAfterExecute = activeMIdAfterExecute;
    }
    execute(){
        let len = this.ops.length;
        for (let i = 0; i < len; i++) {
            this.ops[i].execute();
        }
    }
    undo(){
        let len = this.ops.length;
        for (let i = len - 1; i >= 0; i--) {
            this.ops[i].undo();
        }
    }
}

interface Operation{
    undo():void;
    execute():void;
    type:string;
}
//操作类型
enum OperationType{
    AddOperation="AddOperation",
    ModifyOperation="ModifyOperation",
    RemoveOperation="RemoveOperation",
    EraserOperation="EraserOperation",
    ClipPathGroupAddOperation="ClipPathGroupAddOperation",
    ClipPathGroupRemoveOperation="ClipPathGroupRemoveOperation",
    GroupAddOperation="GroupAddOperation",
    GroupRemoveOperation="GroupRemoveOperation",
}

export class AddOperation implements Operation{
    obj:any;
    controller:any;
    type=OperationType.AddOperation;
    constructor(obj:any, controller:any) {
        this.obj = obj;
        this.controller = controller;
    }
    execute() {
        this.controller.add(this.obj);
    }
    undo() {
        let object = getObjectByMId(this.controller,this.obj.mid);
        this.controller.remove(object);
    }
}


export class ModifyOperation implements Operation{
    obj:any;
    type=OperationType.ModifyOperation;
    state:any={};
    prevState:any={};
    stateProperties:Array<string>=[];
    constructor(obj:any, prevState :any) {
        this.obj = obj;
        this.prevState = prevState;
        this.state = {};
        this.stateProperties = Array.from(this.obj.stateProperties);
        this.stateProperties.forEach((prop) => {
            this.state[prop] = this.obj.get(prop);
        });
    }
    execute() {
        this._restore(this.state);
        this.obj.setCoords();
    }
    undo() {
        this._restore(this.prevState);
        this.obj.setCoords();
    }
    _restore(state:any) {
        this.stateProperties.forEach((prop) => {
            this.obj.set(prop, state[prop]);
        });
    }
}

export class RemoveOperation implements Operation{
    obj:any;
    type=OperationType.RemoveOperation;
    controller:any
    constructor(obj:any, controller:any) {
        this.obj = obj;
        this.controller = controller;
    }
    execute() {
        let object = getObjectByMId(this.controller,this.obj.mid);
        this.controller.remove(object);
    }
    undo() {
        this.controller.add(this.obj);
    }
}
export class EraserOperation implements Operation{
    type=OperationType.EraserOperation;
    controller:any;
    //本次擦除影响到的对象。
    targets:Array<any>=[];
    //本次擦除影响到的对象的path。
    targetPaths:Array<any>=[];
    constructor(targets:any, controller:any) {
        this.targets = targets;
        this.controller = controller;
        this.parseTargetEraser();
    }
    parseTargetEraser():void{
        for(let item of this.targets){
            let eraser = item.get('eraser');
            if (eraser && eraser.size() > 0) {
                let path = eraser.item(eraser.size()-1);
                // 保存当前path
                this.targetPaths.push(path)
            }
        }
    }
    execute() {
        for (let i = 0; i < this.targets.length; i++) {
            let item = this.targets[i];
            let eraser = item.get('eraser');
            //将轨迹添加到目标对象。
            eraser.add(this.targetPaths[i]);
            item.set('dirty',true)
        }
    }
    undo() {
        for (let i = 0; i < this.targets.length; i++) {
            let item = this.targets[i];
            let eraser = item.get('eraser');
            // 去除最后一条轨迹。
            eraser.remove(eraser.item(eraser.size() - 1));
            item.set('dirty',true)
        }
    }
}
export class ClipPathGroupAddOperation implements Operation{
    type=OperationType.ClipPathGroupAddOperation;
    obj:any;
    controller:any;
    path:any;
    constructor(obj:any, controller:any,path:any) {
        this.obj = obj;
        this.controller = controller;
        generateObjectMidIfNotExist(path)
        this.path = path;
    }
    execute() {
        console.log('*********execute ')
        let object = this._getHandleObject();
        let clipPath = object!.clipPath  as fabric.Group;
        clipPath.addWithUpdate(this.path);
        object.set('dirty',true)
    }
    undo() {
        console.log('*********undo ')
        let object = this._getHandleObject();
        let clipPath = object!.clipPath  as fabric.Group;
        let path = getObjectByMId(clipPath,this.path.get('mid'));
        clipPath.removeWithUpdate(path)
        object.set('dirty',true)
    }
    _getHandleObject():fabric.Object{
        let object = getObjectByMId(this.controller,this.obj.mid);
        if(!object){
            throw new Error("can't find the object");
        }
        if(!object!.clipPath||!(object.clipPath instanceof fabric.Group)){
            throw new Error("error operation,object clipPath should be group")
        }
        return object;
    }
}
export class ClipPathGroupRemoveOperation implements Operation{
    type=OperationType.ClipPathGroupRemoveOperation;
    obj:any;
    controller:any;
    path:any;
    constructor(obj:any, controller:any,path:any) {
        this.obj = obj;
        this.controller = controller;
        this.path = path;
    }
    execute() {
        let object = this._getHandleObject();
        new RemoveOperation(this.path,object.clipPath).execute();
    }
    undo() {
        let object = this._getHandleObject();
        new AddOperation(this.path,object.clipPath).execute();
    }
    _getHandleObject():fabric.Object{
        let object = getObjectByMId(this.controller,this.obj.mid);
        if(!object){
            throw new Error("can't find the object");
        }
        if(!object!.clipPath||!(object.clipPath instanceof fabric.Group)){
            throw new Error("error operation,object clipPath should be group")
        }
        return object;
    }
}
export class GroupAddOperation implements Operation{
    type=OperationType.GroupAddOperation;
    obj:any;
    group:fabric.Group;
    canvas:fabric.Canvas;
    constructor(obj:any, group:fabric.Group,canvas:fabric.Canvas) {
        this.obj = obj;
        this.group = group;
        this.canvas = canvas;
        generateObjectMidIfNotExist(obj);
        generateObjectMidIfNotExist(group);
    }
    execute() {
        console.log('*********execute ')
        let group = getObjectByMId(this.canvas,this.group.get('mid')) as fabric.Group;
        group.addWithUpdate(this.obj);
        group.set('dirty',true)
    }
    undo() {
        console.log('*********undo ')
        let group = getObjectByMId(this.canvas,this.group.get('mid')) as fabric.Group;
        group.removeWithUpdate(this.obj);
        group.set('dirty',true)
    }
}
export class GroupRemoveOperation implements Operation{
    type=OperationType.GroupRemoveOperation;
    obj:any;
    group:fabric.Group;
    canvas:fabric.Canvas;
    constructor(obj:any, group:fabric.Group,canvas:fabric.Canvas) {
        this.obj = obj;
        this.group = group;
        this.canvas = canvas;
        generateObjectMidIfNotExist(obj);
        generateObjectMidIfNotExist(group);
    }
    execute() {
        console.log('*********execute ')
        let group = getObjectByMId(this.canvas,this.group.get('mid')) as fabric.Group;
        let obj = getObjectByMId(group,this.obj.get('mid'));
        if(obj){
            group.removeWithUpdate(obj);
            group.set('dirty',true)
        }
    }
    undo() {
        console.log('*********undo ')
        let group = getObjectByMId(this.canvas,this.group.get('mid')) as fabric.Group;
        group.addWithUpdate(this.obj);
        group.set('dirty',true)
    }
}
