import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0fff69de"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "meta-header-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")!
  const _component_MetaButton = _resolveComponent("MetaButton")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.visible,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.visible) = $event)),
    "show-close": false,
    width: "745px",
    "append-to-body": "",
    onOpened: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('opened'))),
    "close-on-click-modal": false,
    "custom-class": "meta-dialog",
    "before-close": (done) => {_ctx.$emit('beforeClose');done()},
    onClosed: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('closed')))
  }, {
    header: _withCtx(({ close, titleId, titleClass }) => [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.title), 1),
      _createVNode(_component_MetaButton, {
        onClick: close,
        class: "meta-dialog-close-btn"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_SvgIcon, { name: "close" })
        ]),
        _: 2
      }, 1032, ["onClick"])
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 3
  }, 8, ["modelValue", "before-close"]))
}